import styled from "styled-components";

const Content = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.font300};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSize.s};
  font-family: ${({ theme }) => theme.roboto};
  line-height: ${({ theme }) => theme.fontSize.xl};
  margin-bottom: 0.5rem;
`;

export default Content;
