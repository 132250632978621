import React from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

const ImageBox = styled.div`
  width: 55%;

  @media (max-width: 768px) {
    width: 70%;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const SideImage = ({ src }) => (
  <ImageBox>
    {src ? <Image src={src} alt="Image" /> : <Skeleton height={"40vh"} />}
  </ImageBox>
);

export default SideImage;
