import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faTwitter,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { routes } from "../../routes";
import { SOCIAL_MEDIA } from "../../utils";
import ScrollToTop from "../../hooks/ScrollToTop";

import footerBannerLogo1 from "../../images/logo_pge.png";
import footerBannerLogo2 from "../../images/partner_logo.png";
import footerBannerLogo3 from "../../images/patron_logo_2.png";
import footerBannerLogo4 from "../../images/patron_logo_3.png";
import footerBannerLogo5 from "../../images/patron_logo_1.png";

const Container = styled.div`
  background-color: ${({ theme }) => theme.black300};
  padding: 90px 15px;
`;

const MainBox = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ isCenter }) => (isCenter ? "center" : "space-between")};

  :first-of-type {
    margin-bottom: 64px;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    :first-of-type {
      margin-bottom: 40px;
    }
  }
`;

const ImageBannerBox = styled.div`
  overflow: hidden;
  border-radius: 4px;
  /* background-color: white; */
  max-width: 640px;
  padding: 18px 24px;
  display: flex;
  align-items: center;

  @media (max-width: 536px) {
    justify-content: center;
  }
`;

const WhiteBannerImage = styled.img`
  max-height: 60px;
  width: auto;
  padding: 0 10px;

  @media (max-width: 600px) {
    max-width: 20%;
  }
`;

const RowElement = styled.div``;

const Title = styled.h3`
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 536px) {
    margin-top: 40px;
  }
`;

const Icons = styled.div`
  display: flex;
  justify-content: center;
`;

const IconBox = styled.a`
  text-decoration: none;
  margin: 0 15px;
  color: ${({ theme }) => theme.font200};
  font-size: 30px;
  transition: 0.3s ease-in-out;

  :hover {
    color: ${({ theme }) => theme.red100};
  }
`;

const ImageLogoBox = styled.div`
  margin-bottom: 14px;
  width: ${({ width }) => width}px;
`;

const LinkList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 768px) {
    margin-top: 40px;
    align-items: center;
  }
`;

const StyledLink = styled(Link)`
  margin: 8px 0;
  text-decoration: none;
  color: #fff;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  user-select: none;
  transition: color 0.3s ease-in-out;

  :hover {
    color: ${({ theme }) => theme.red100};
  }

  ::first-letter {
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
    margin: 8px 0;
  }
`;

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <MainBox>
        <Row>
          <RowElement>
            <ImageLogoBox width={80}></ImageLogoBox>
            <ImageLogoBox width={95}></ImageLogoBox>
          </RowElement>
          <RowElement>
            <Title>{t("footer_title")}</Title>
            <Icons>
              <IconBox
                href={SOCIAL_MEDIA.facebook}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookSquare} />
              </IconBox>
              <IconBox
                href={SOCIAL_MEDIA.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </IconBox>
              <IconBox
                href={SOCIAL_MEDIA.twitter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </IconBox>
              <IconBox
                href={SOCIAL_MEDIA.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </IconBox>
              <IconBox
                href={SOCIAL_MEDIA.youtube}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </IconBox>
            </Icons>
          </RowElement>
          <RowElement>
            <ScrollToTop />
            <LinkList>
              <StyledLink to={routes.project}>{t("project idea")}</StyledLink>
              <StyledLink to={routes.education}>{t("education")}</StyledLink>
              <StyledLink to={routes.media}>{t("multimedia")}</StyledLink>
            </LinkList>
          </RowElement>
        </Row>
        <Row isCenter={true}>
          <ImageBannerBox>
            <WhiteBannerImage src={footerBannerLogo1} />
            <WhiteBannerImage src={footerBannerLogo2} />
            <WhiteBannerImage src={footerBannerLogo5} />
            <WhiteBannerImage src={footerBannerLogo3} />
            <WhiteBannerImage src={footerBannerLogo4} />
          </ImageBannerBox>
        </Row>
      </MainBox>
    </Container>
  );
};

export default Footer;
