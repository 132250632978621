import React, { useContext, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import styled, { css } from "styled-components";
import { Transition } from "react-transition-group";
import { gsap } from "gsap";
import { useHistory } from "react-router-dom";

import { SideModalContext } from "../../context/SideModalContext";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";

const ModalBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: ${({ map, background }) =>
    map ? "transparent" : background};
  z-index: 91;
  overflow: hidden;

  ${({ left }) =>
    left &&
    css`
      justify-content: flex-start;
    `};
`;

const MainContentBox = styled.div`
  width: ${({ width }) => width}px;
  max-width: 94%;
  height: calc(100% - ${({ map }) => (map ? 90 : 0)}px);
  margin-top: ${({ map }) => (map ? 90 : 0)}px;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 0 20px 0 rgba(10, 16, 21, 0.08);
`;

const SideModal = (props) => {
  const { map, left } = props;
  const history = useHistory();

  const { sideModal, handleSideModal, sideModalContent } = useContext(
    SideModalContext
  );

  const modalContentBox = useRef(null);
  const modalBackground = useRef(null);

  const listener = (e) => {
    if (!modalContentBox.current || modalContentBox.current.contains(e.target))
      return;
    handleSideModal(false);
  };

  useEffect(() => {
    const unlisten = history.listen(() => {
      handleSideModal(false);
    });
    return () => {
      unlisten();
    };
    // eslint-disable-next-line
  }, [history]);

  useEffect(() => {
    if (modalBackground.current)
      modalBackground.current.addEventListener("mousedown", listener);
  });

  useDetectOutsideClick(modalContentBox, handleSideModal, map);

  // Animations
  const enterAnimation = (node, done) => {
    gsap
      .timeline()
      .from(node, {
        autoAlpha: 0,
        duration: 0.2,
        ease: "Power2.inOut",
      })
      .from(
        node.childNodes,
        {
          x: left ? "-100%" : "100%",
          duration: 0.2,
          onComplete: done,
          ease: "Power2.inOut",
        },
        "-=.1"
      );
  };

  const exitAnimation = (node, done) => {
    gsap
      .timeline()
      .to(node.childNodes, {
        x: left ? "-100%" : "100%",
        duration: 0.2,
        ease: "Power2.inOut",
      })
      .to(
        node,
        {
          autoAlpha: 0,
          duration: 0.2,
          onComplete: done,
          ease: "Power2.inOut",
        },
        "-=.1"
      );
  };

  return createPortal(
    <Transition
      timeout={200}
      mountOnEnter
      unmountOnExit
      in={sideModal}
      onEnter={(node, done) => {
        enterAnimation(node, done);
      }}
      onExit={(node, done) => {
        exitAnimation(node, done);
      }}
    >
      <ModalBackground ref={modalBackground} {...props}>
        <MainContentBox ref={modalContentBox} {...props}>
          {sideModalContent}
        </MainContentBox>
      </ModalBackground>
    </Transition>,
    document.querySelector("#side-modal-root")
  );
};

export default SideModal;
