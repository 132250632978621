import axios from "axios";

import { BASE_URL } from "../utils";

export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const MENU_SUCCESS = "MENU_SUCCESS";
export const GLOBAL_SUCCESS = "GLOBAL_SUCCESS";

export const fetchItems = (itemSlug, lang) => (dispatch) => {
  dispatch({ type: "FETCH_REQUEST" });
  return axios
    .get(`${BASE_URL}/wp-json/wp/v2/pages`, {
      params: {
        slug: itemSlug,
        lang: lang,
      },
    })
    .then(({ data }) =>
      dispatch({
        type: "FETCH_SUCCESS",
        payload: {
          ...data[0],
          lang,
        },
      })
    )
    .catch((err) => {
      console.log(err);
      dispatch({ type: "FETCH_FAILURE" });
    });
};

export const fetchMenu = (lang) => (dispatch) => {
  dispatch({ type: "MENU_REQUEST" });
  return axios
    .get(`${BASE_URL}/wp-json/wp-api-menus/v2/menus/26`, {
      params: {
        lang: lang,
      },
    })
    .then(({ data }) =>
      dispatch({
        type: "MENU_SUCCESS",
        payload: {
          ...data,
          lang,
        },
      })
    )
    .catch((err) => {
      console.log(err);
      dispatch({ type: "MENU_FAILURE" });
    });
};

export const fetchGlobal = () => (dispatch) => {
  dispatch({ type: "GLOBAL_REQUEST" });
  return axios
    .get(`${BASE_URL}/wp-json/acf/v3/options/general`)
    .then(({ data }) =>
      dispatch({
        type: "GLOBAL_SUCCESS",
        payload: {
          ...data.acf,
        },
      })
    )
    .catch((err) => {
      console.log(err);
      dispatch({ type: "GLOBAL_FAILURE" });
    });
};

// slug, lang
