import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

export const useGetSearchParameters = () => {
  const history = useHistory();
  const [searchObject, setSearchObject] = useState(false);

  useEffect(() => {
    const search = history.location.search.substring(1);

    if (search.length > 0) {
      const searchObject = JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      setSearchObject(searchObject);
    }
  }, [history]);

  const isSearchSet = searchObject ? true : false;

  return { searchParams: searchObject, isSearchSet };
};
