import React from "react";

export const LogoEnSvg = ({ dark }) => {
  const fill = dark ? "#2a2828" : "#fff";

  return (
    <svg
      id="logo"
      viewBox="0 0 91.83 51.74"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="tp-black" fillRule="nonzero">
          <path
            fill={fill}
            d="M18.18,33.5v2.94H15.12V27.3H19a6.19,6.19,0,0,1,1.37.15,3.4,3.4,0,0,1,1.2.51,2.6,2.6,0,0,1,.86.94,3.09,3.09,0,0,1,.32,1.48,3,3,0,0,1-.32,1.44,2.73,2.73,0,0,1-.84,1,3.45,3.45,0,0,1-1.2.54A5.81,5.81,0,0,1,19,33.5Zm0-3.81v1.47h.65a1.24,1.24,0,0,0,.63-.17.62.62,0,0,0,.29-.59.73.73,0,0,0-.07-.35.66.66,0,0,0-.22-.22,1.33,1.33,0,0,0-.3-.11,1.84,1.84,0,0,0-.33,0Z"
          />
          <path fill={fill} d="M24,27.3h3.1v6.5h3v2.64H24Z" />
          <path
            fill={fill}
            d="M33.58,27.3h3.26l3.56,9.14H37.06l-.48-1.34h-2.9l-.45,1.34H30Zm1.59,3.19-.75,2.26H35.9Z"
          />
          <path
            fill={fill}
            d="M49.52,34.12,51,34v2.41H45.31a6.29,6.29,0,0,1-2-.3,4.19,4.19,0,0,1-1.55-.9,4,4,0,0,1-1-1.46,5,5,0,0,1-.37-2,4.64,4.64,0,0,1,.39-1.92,4.34,4.34,0,0,1,1.07-1.49,4.7,4.7,0,0,1,1.6-1,5.75,5.75,0,0,1,2-.34,5.64,5.64,0,0,1,2,.34,4.73,4.73,0,0,1,1.58.95,4.27,4.27,0,0,1,1,1.46,4.5,4.5,0,0,1,.37,1.85,3.94,3.94,0,0,1-.08.77,5.05,5.05,0,0,1-.22.69,3.28,3.28,0,0,1-.31.56,2.43,2.43,0,0,1-.34.4Zm-5.85-2.3a2.45,2.45,0,0,0,.14.83,1.92,1.92,0,0,0,.37.64,1.69,1.69,0,0,0,.57.42,1.8,1.8,0,0,0,.73.15,1.71,1.71,0,0,0,.71-.15,1.83,1.83,0,0,0,.58-.42,1.94,1.94,0,0,0,.38-.64,2.45,2.45,0,0,0,.13-.83,2.37,2.37,0,0,0-.13-.81,1.85,1.85,0,0,0-.38-.62,2.11,2.11,0,0,0-.58-.39,1.87,1.87,0,0,0-.71-.14,2,2,0,0,0-.73.14,1.92,1.92,0,0,0-.57.39,1.83,1.83,0,0,0-.37.62A2.38,2.38,0,0,0,43.67,31.82Z"
          />
          <path
            fill={fill}
            d="M56.07,36.7a5.26,5.26,0,0,1-1.86-.3,3.64,3.64,0,0,1-1.32-.81,3.1,3.1,0,0,1-.79-1.22,4.41,4.41,0,0,1-.26-1.52V27.3h3.07v5.22a1.5,1.5,0,0,0,.32,1,1.27,1.27,0,0,0,1.79,0,1.5,1.5,0,0,0,.32-1V27.3H60.4v5.55a4.19,4.19,0,0,1-.28,1.52,3.41,3.41,0,0,1-.82,1.22A3.77,3.77,0,0,1,58,36.4,5.37,5.37,0,0,1,56.07,36.7Z"
          />
          <path
            fill={fill}
            d="M62,27.3h6.29v2.56h-3.4v.79h3.19V33H64.86v.86h3.62v2.55H62Z"
          />
          <path
            fill={fill}
            d="M72.75,36.7a5.6,5.6,0,0,1-1.09-.1,5,5,0,0,1-1-.28,5.41,5.41,0,0,1-.94-.44,4.61,4.61,0,0,1-.79-.55l1.87-2a3.07,3.07,0,0,0,.86.62,2.45,2.45,0,0,0,1.1.27,1.24,1.24,0,0,0,.48-.09.33.33,0,0,0,.21-.33.32.32,0,0,0-.07-.22.77.77,0,0,0-.25-.19,2.78,2.78,0,0,0-.5-.21l-.79-.28a6.37,6.37,0,0,1-.89-.36,3.22,3.22,0,0,1-.76-.53,2.3,2.3,0,0,1-.52-.74,2.54,2.54,0,0,1-.2-1,2.86,2.86,0,0,1,.35-1.45,3,3,0,0,1,.9-1,4,4,0,0,1,1.23-.55,5.8,5.8,0,0,1,1.36-.16,5.32,5.32,0,0,1,1.84.34,5,5,0,0,1,1.64,1l-1.91,2a2,2,0,0,0-.76-.59,2.08,2.08,0,0,0-.81-.2,1.23,1.23,0,0,0-.55.1.33.33,0,0,0-.2.32.34.34,0,0,0,.21.31,7.31,7.31,0,0,0,.69.25l1,.36a4.17,4.17,0,0,1,1,.54,2.79,2.79,0,0,1,.76.81,2.18,2.18,0,0,1,.3,1.18,3,3,0,0,1-.33,1.44,3.07,3.07,0,0,1-.87,1,3.68,3.68,0,0,1-1.2.61A4.94,4.94,0,0,1,72.75,36.7Z"
          />
          <path
            fill={fill}
            d="M17.93,18l.14,5.28H15.14V13.93h4.11l1.69,5H21l1.46-5h4.24v9.34H23.65l.1-5.25-.08,0-1.75,5.27H19.71L18,18Z"
          />
          <path
            fill={fill}
            d="M28.44,13.93h6.43v2.61H31.4v.8h3.26v2.45H31.4v.87h3.69v2.61H28.44Z"
          />
          <path
            fill={fill}
            d="M39.19,18l.14,5.28H36.4V13.93h4.11l1.69,5h.07l1.46-5H48v9.34H44.91L45,18l-.08,0-1.75,5.27H41L39.25,18Z"
          />
          <path
            fill={fill}
            d="M49.28,18.55a4.9,4.9,0,0,1,.4-2A4.36,4.36,0,0,1,50.77,15a4.73,4.73,0,0,1,1.64-1,6.24,6.24,0,0,1,4.12,0,4.86,4.86,0,0,1,1.65,1,4.49,4.49,0,0,1,1.09,1.54,4.9,4.9,0,0,1,.4,2,5.05,5.05,0,0,1-.4,2,4.65,4.65,0,0,1-1.09,1.58,5.12,5.12,0,0,1-1.65,1,6.24,6.24,0,0,1-4.12,0,5,5,0,0,1-1.64-1,4.52,4.52,0,0,1-1.09-1.58A5.05,5.05,0,0,1,49.28,18.55Zm3.34,0a2.51,2.51,0,0,0,.14.84,2.15,2.15,0,0,0,.38.66,1.7,1.7,0,0,0,.59.43,1.81,1.81,0,0,0,.74.15,1.77,1.77,0,0,0,.73-.15,1.91,1.91,0,0,0,.59-.43,2.4,2.4,0,0,0,.39-.66,2.51,2.51,0,0,0,.14-.84,2.45,2.45,0,0,0-.14-.83,2,2,0,0,0-.39-.63,2,2,0,0,0-.59-.41,1.94,1.94,0,0,0-.73-.14,2,2,0,0,0-.74.14,1.74,1.74,0,0,0-1,1A2.45,2.45,0,0,0,52.62,18.55Z"
          />
          <path
            fill={fill}
            d="M61,13.93h4a7.06,7.06,0,0,1,1.37.13,3.62,3.62,0,0,1,1.21.49,2.71,2.71,0,0,1,.87.94A3,3,0,0,1,68.79,17a2.72,2.72,0,0,1-.42,1.51,2.78,2.78,0,0,1-1.22,1l2.38,3.79H65.92l-1.79-3.34H64v3.34H61Zm3,3.94h.62l.34,0a1.09,1.09,0,0,0,.36-.12.66.66,0,0,0,.38-.64.73.73,0,0,0-.09-.39.56.56,0,0,0-.24-.22.8.8,0,0,0-.31-.09,1.72,1.72,0,0,0-.31,0H64Z"
          />
          <path fill={fill} d="M70.41,13.93h3.17v9.34H70.41Z" />
          <path
            fill={fill}
            d="M77.92,13.93h3.34l3.64,9.34H81.48L81,21.9H78l-.46,1.37H74.24Zm1.62,3.26-.76,2.31h1.51Z"
          />
          <path fill={fill} d="M85.56,13.93h3.17v6.63h3.1v2.71H85.56Z" />
          <polygon
            id="Path"
            fill="#ED1C24"
            points="3.04 35.97 3.04 48.83 13.78 48.83 16.7 41.38 9.16 38.43 9.08 33.61 5.19 35.13"
          ></polygon>
          <polygon
            id="Path"
            fill="#ED1C24"
            points="15.86 51.87 24.35 51.87 24.35 44.37 19.53 42.49"
          ></polygon>
          <polygon
            id="Path"
            fill="#ED1C24"
            points="15.86 0.13 19.43 9.26 24.35 9.26 24.35 0.13"
          ></polygon>
          <polygon
            id="Path"
            fill="#ED1C24"
            points="13.78 3.17 16.16 9.26 9.13 9.26 9.08 18.39 3.04 16.03 3.04 3.17"
          ></polygon>
          <polygon
            id="Path"
            fill="#ED1C24"
            points="9.13 21.68 9.13 30.32 0 33.89 0 18.11"
          ></polygon>
        </g>
      </g>
    </svg>
  );
};
