import styled, { css } from "styled-components";

const Button = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-family: ${({ theme }) => theme.poppins};
  color: ${({ theme }) => theme.font100};
  background: ${({ theme }) => theme.red100};
  text-transform: uppercase;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.red100};
  padding: 12px 20px;
  transition: all ease 0.3s;
  outline-style: none;
  min-width: 120px;
  width: fit-content;
  margin-top: 22px;
  text-decoration: none;

  ${({ secondary }) =>
    secondary &&
    css`
      background-color: ${({ theme }) => theme.grey200};
      width: 105px;
      height: 30px;
      font-size: 10px;
      letter-spacing: 1px;
    `}

  &:hover {
    background: transparent;
    color: ${({ theme }) => theme.red100};
    cursor: pointer;
  }
`;

export default Button;
