import { useEffect } from "react";

import NativeAppLauncher from "../components/DeepLink";
import { appstore_url, googleplay_url } from "../variables";

export const useRedirectToMobileApp = (placeId) => {
  useEffect(() => {
    const openDownloadAppLink = (downloadAppLink) => {
      setTimeout(() => {
        window.location = downloadAppLink;
      }, 50);
    };

    const redirectToApp = (placeId, downloadAppLink) => {
      window.location = `tablicepamieci://place/${placeId}`;
      openDownloadAppLink(downloadAppLink);
    };

    if (placeId) {
      if (NativeAppLauncher.browserChecker().isAndroid) {
        redirectToApp(placeId, googleplay_url);
      } else if (NativeAppLauncher.browserChecker().isIOS) {
        redirectToApp(placeId, appstore_url);
      }
    }
  }, [placeId]);
};
