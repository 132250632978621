import React from "react";

export const LogoSvg = ({ dark }) => {
  const fill = dark ? "#2a2828" : "#fff";

  return (
    <svg
      id="logo"
      viewBox="0 0 70 52"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="tp-black" fillRule="nonzero">
          <polygon
            id="Path"
            fill={fill}
            points="20.67 23.4 17.52 23.4 17.52 16.7 15.22 16.7 15.22 14 23 14 23 16.7 20.7 16.7"
          ></polygon>
          <path
            d="M25.44,14 L28.78,14 L32.43,23.36 L29,23.36 L28.52,22 L25.52,22 L25.06,23.37 L21.75,23.37 L25.44,14 Z M27.06,17.26 L26.3,19.58 L27.82,19.58 L27.06,17.26 Z"
            id="Shape"
            fill={fill}
          ></path>
          <path
            d="M39.22,18.53 C39.443621,18.5888005 39.6587276,18.6761875 39.86,18.79 C40.0746916,18.9057595 40.2675751,19.058036 40.43,19.24 C40.6007552,19.4237584 40.7363345,19.6372958 40.83,19.87 C40.9329678,20.1204626 40.9839991,20.3892272 40.98,20.66 C41.0105385,21.5259868 40.5757275,22.342211 39.84,22.8 C39.4867932,23.0270501 39.0928207,23.1832806 38.68,23.26 C38.2392568,23.352116 37.7902655,23.3990256 37.34,23.4 L33.09,23.4 L33.09,14 L36.94,14 C37.344536,14.003379 37.7485404,14.03009 38.15,14.08 C38.561772,14.1372707 38.9627903,14.2552173 39.34,14.43 C39.697826,14.59681 40.0049534,14.8556251 40.23,15.18 C40.4850582,15.564085 40.6111526,16.0194258 40.59,16.48 C40.6097316,16.9262958 40.4794757,17.3663495 40.22,17.73 C39.9587116,18.0568588 39.6128565,18.3058746 39.22,18.45 L39.22,18.53 Z M36,16.48 L36,17.63 L36.85,17.63 C37.041298,17.6380541 37.2302626,17.5855639 37.39,17.48 C37.5349759,17.368926 37.6139839,17.1920986 37.6,17.01 C37.6059656,16.8462665 37.5161735,16.6940104 37.37,16.62 C37.1899481,16.5199693 36.9857985,16.4715271 36.78,16.48 L36,16.48 Z M36,19.66 L36,20.93 L37,20.93 C37.2130079,20.9387381 37.4232339,20.8791741 37.6,20.76 C37.7526635,20.6489883 37.8391295,20.4685375 37.83,20.28 C37.8370234,20.1707909 37.8011442,20.0631532 37.73,19.98 C37.6643061,19.8978305 37.5826689,19.8297995 37.49,19.78 C37.3903376,19.7362077 37.2864661,19.7027008 37.18,19.68 L36.87,19.68 L36,19.66 Z"
            id="Shape"
            fill={fill}
          ></path>
          <polygon
            id="Path"
            fill={fill}
            points="42 14 45.17 14 45.17 20.65 48.28 20.65 48.28 23.4 42 23.4"
          ></polygon>
          <polygon
            id="Path"
            fill={fill}
            points="49.52 14 52.69 14 52.69 23.4 49.52 23.4"
          ></polygon>
          <path
            d="M53.71,18.72 C53.7054981,18.0340123 53.8380931,17.354038 54.1,16.72 C54.353401,16.1312499 54.7243042,15.6004159 55.19,15.16 C55.6609711,14.7259898 56.2109385,14.3865038 56.81,14.16 C57.45012,13.9237339 58.1276864,13.8051598 58.81,13.81 C59.491555,13.8139291 60.1676155,13.9322396 60.81,14.16 C61.4037401,14.3680927 61.9507792,14.6908798 62.42,15.11 L60.53,17.4 C60.3558178,17.1728329 60.1294167,16.991026 59.87,16.87 C59.5939148,16.7569608 59.2983291,16.6992027 59,16.7 C58.7473357,16.696196 58.4962392,16.7403076 58.26,16.83 C58.0339334,16.923952 57.8296773,17.063527 57.66,17.24 C57.4847763,17.4241019 57.3487015,17.6418217 57.26,17.88 C57.1566387,18.1478022 57.105715,18.4329753 57.11,18.72 C57.1057474,19.0037725 57.1566921,19.2856662 57.26,19.55 C57.3585234,19.7795043 57.4973857,19.9894913 57.67,20.17 C57.8451932,20.3444791 58.0522809,20.4836692 58.28,20.58 C58.5093174,20.670653 58.7534217,20.7181178 59,20.72 C59.3282427,20.7285799 59.6528017,20.6491666 59.94,20.49 C60.1795309,20.3599032 60.3877246,20.1790136 60.55,19.96 L62.48,22.28 C62.0328689,22.7152642 61.5021969,23.0554385 60.92,23.28 C60.2510356,23.540836 59.5379495,23.6698707 58.82,23.66 C58.1366592,23.6664505 57.4582007,23.544328 56.82,23.3 C56.2209385,23.0734962 55.6709711,22.7340102 55.2,22.3 C54.7387974,21.8557277 54.3685674,21.3258572 54.11,20.74 C53.840434,20.1009516 53.7043155,19.4135534 53.71,18.72 L53.71,18.72 Z"
            id="Path"
            fill={fill}
          ></path>
          <polygon
            id="Path"
            fill={fill}
            points="63.33 14 69.78 14 69.78 16.62 66.3 16.62 66.3 17.42 69.56 17.42 69.56 19.87 66.3 19.87 66.3 20.74 70 20.74 70 23.4 63.33 23.4"
          ></polygon>
          <path
            d="M18.26,33.69 L18.26,36.62 L15.26,36.62 L15.26,27.54 L19.16,27.54 C19.6168502,27.5415284 20.0724193,27.5884252 20.52,27.68 C20.9434174,27.7740305 21.3464904,27.9433889 21.71,28.18 C22.0626527,28.4228835 22.3537143,28.7447634 22.56,29.12 C22.9863757,30.0431453 22.9863757,31.1068547 22.56,32.03 C22.3637352,32.4226081 22.0797374,32.7647741 21.73,33.03 C21.3686191,33.2793643 20.9656344,33.4622314 20.54,33.57 C20.0880887,33.6785259 19.6247544,33.7322458 19.16,33.73 L18.26,33.69 Z M18.26,29.91 L18.26,31.3700045 L18.9,31.3700045 C19.1214113,31.3705954 19.3389436,31.3118962 19.53,31.2 C19.7315605,31.0749916 19.8441418,30.8459468 19.82,30.61 C19.8259874,30.4893384 19.8019352,30.3690771 19.75,30.26 C19.6949173,30.1730837 19.619383,30.1009828 19.53,30.05 C19.4365423,29.9974905 19.3352495,29.9603498 19.23,29.94 L18.9,29.94 L18.26,29.91 Z"
            id="Shape"
            fill={fill}
          ></path>
          <path
            d="M25.44,27.54 L28.69,27.54 L32.23,36.62 L28.9,36.62 L28.43,35.28 L25.54,35.28 L25.09,36.62 L21.86,36.62 L25.44,27.54 Z M27,30.7 L26.27,33 L27.75,33 L27,30.7 Z"
            id="Shape"
            fill={fill}
          ></path>
          <polygon
            id="Path"
            fill={fill}
            points="35.57 31.49 35.71 36.62 32.87 36.62 32.87 27.54 36.87 27.54 38.51 32.42 38.58 32.42 40 27.54 44.13 27.54 44.13 36.62 41.13 36.62 41.22 31.51 41.14 31.51 39.45 36.63 37.3 36.63 35.64 31.5"
          ></polygon>
          <polygon
            id="Path"
            fill={fill}
            points="45.49 27.54 48.57 27.54 48.57 36.62 45.49 36.62"
          ></polygon>
          <path
            d="M50,27.54 L56.2,27.54 L56.2,30.08 L52.83,30.08 L52.83,30.86 L56,30.86 L56,33.23 L52.83,33.23 L52.83,34.08 L56.42,34.08 L56.42,36.62 L56.13,36.62 C55.7312237,36.8874647 55.3534584,37.1849967 55,37.51 C54.8320849,37.6443107 54.7213124,37.8372692 54.69,38.05 C54.69,38.22 54.78,38.31 54.98,38.31 C55.2359447,38.2974001 55.4841496,38.2181124 55.7,38.08 L56.26,39.14 C56.0043171,39.3068312 55.7288674,39.441197 55.44,39.54 C55.1171927,39.6457458 54.7796861,39.6997468 54.44,39.7 C54.2551355,39.6982062 54.070903,39.6781082 53.89,39.64 C53.7040287,39.6120206 53.5245462,39.5510643 53.36,39.46 C53.2016568,39.3668044 53.0652328,39.2406123 52.96,39.09 C52.8541669,38.9082496 52.8021474,38.7001718 52.81,38.49 C52.8099452,38.2882922 52.8579362,38.0894723 52.95,37.91 C53.051711,37.7308984 53.1724062,37.563266 53.31,37.41 C53.4641609,37.2565399 53.627817,37.1129234 53.8,36.98 L54.34,36.62 L50,36.62 L50,27.54 Z"
            id="Path"
            fill={fill}
          ></path>
          <path
            d="M57.28,32.08 C57.2640522,31.3939824 57.3935597,30.7123639 57.66,30.08 C57.8911438,29.51599 58.2309207,29.0029269 58.66,28.57 C59.1125849,28.1367986 59.6460744,27.7969964 60.23,27.57 C60.8472708,27.3401195 61.501333,27.2248961 62.16,27.23 C62.8417026,27.2219579 63.5192506,27.337141 64.16,27.57 C64.7398284,27.7680719 65.2737918,28.0809676 65.73,28.49 L63.85,30.74 C63.6842135,30.5200846 63.4634951,30.3476484 63.21,30.24 C62.9402197,30.1169944 62.6464652,30.0555109 62.35,30.06 C62.1064751,30.0599908 61.8652931,30.1075478 61.64,30.2 C61.4180282,30.2869329 61.2169592,30.4198429 61.05,30.59 C60.8804006,30.7689372 60.7478585,30.9796451 60.66,31.21 C60.5657581,31.4730479 60.5183738,31.7505843 60.52,32.03 C60.514635,32.3041294 60.5656922,32.5764342 60.67,32.83 C60.7640605,33.0541919 60.8959839,33.2605337 61.06,33.44 C61.2287716,33.6079454 61.4293528,33.740533 61.65,33.83 C62.1741273,34.0303648 62.7583175,34.0013367 63.26,33.75 C63.4909528,33.6199543 63.6919708,33.442786 63.85,33.23 L65.73,35.48 C65.2944598,35.9105847 64.7778194,36.2504797 64.21,36.48 C62.9188496,36.954316 61.5011504,36.954316 60.21,36.48 C58.3665811,35.7841113 57.1637087,33.9998506 57.21,32.03 L57.28,32.08 Z"
            id="Path"
            fill={fill}
          ></path>
          <polygon
            id="Path"
            fill={fill}
            points="66.92 27.54 70 27.54 70 36.62 66.92 36.62"
          ></polygon>
          <polygon
            id="Path"
            fill="#ED1C24"
            points="3.04 35.97 3.04 48.83 13.78 48.83 16.7 41.38 9.16 38.43 9.08 33.61 5.19 35.13"
          ></polygon>
          <polygon
            id="Path"
            fill="#ED1C24"
            points="15.86 51.87 24.35 51.87 24.35 44.37 19.53 42.49"
          ></polygon>
          <polygon
            id="Path"
            fill="#ED1C24"
            points="15.86 0.13 19.43 9.26 24.35 9.26 24.35 0.13"
          ></polygon>
          <polygon
            id="Path"
            fill="#ED1C24"
            points="13.78 3.17 16.16 9.26 9.13 9.26 9.08 18.39 3.04 16.03 3.04 3.17"
          ></polygon>
          <polygon
            id="Path"
            fill="#ED1C24"
            points="9.13 21.68 9.13 30.32 0 33.89 0 18.11"
          ></polygon>
        </g>
      </g>
    </svg>
  );
};
