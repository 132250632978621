import React from "react";

export const LogoDeSvg = ({ dark }) => {
  const fill = dark ? "#2a2828" : "#fff";

  return (
    <svg
      id="logo"
      viewBox="0 0 91.83 51.74"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="tablice_pamieci_DE-2"
          transform="translate(-9.000000, -9.000000)"
        >
          <g id="Logo" transform="translate(8.999900, 9.130600)">
            <polygon
              id="Fill-1"
              fill="#E30613"
              points="3.043 35.8398 3.043 48.6958 13.782 48.6958 16.695 41.2488 9.16 38.2998 9.078 33.4778 5.189 34.9998"
            ></polygon>
            <polygon
              id="Fill-2"
              fill="#E30613"
              points="15.8581 51.7391 24.3481 51.7391 24.3481 44.2431 19.5301 42.3581"
            ></polygon>
            <polygon
              id="Fill-3"
              fill="#E30613"
              points="15.8581 0 19.4311 9.13 24.3481 9.13 24.3481 0"
            ></polygon>
            <polygon
              id="Fill-4"
              fill="#E30613"
              points="13.7819 3.0433 16.1639 9.1303 9.1299 9.1303 9.0779 18.2603 3.0429 15.8993 3.0429 3.0433"
            ></polygon>
            <polygon
              id="Fill-5"
              fill="#E30613"
              points="9.13 21.5489 9.13 30.1899 0 33.7629 0 17.9759"
            ></polygon>
            <g
              id="Group-28"
              transform="translate(14.295100, 13.573500)"
              fill={fill}
            >
              <path
                d="M5.3301,0 C6.7601,0 8.3201,0.584 9.3091,1.517 L9.3601,1.559 L7.5661,3.883 L7.5271,3.841 C6.9291,3.229 6.0451,2.825 5.3171,2.825 C4.1861,2.825 3.3541,3.729 3.3541,4.981 C3.3541,6.262 4.1991,7.167 5.3431,7.167 C5.6291,7.167 6.0711,7.041 6.4741,6.874 L6.4741,4.578 L9.1911,4.578 L9.1911,8.711 L9.1651,8.726 C8.1381,9.463 6.4481,9.991 5.1351,9.991 C2.2491,9.991 0.1301,7.876 0.1301,4.981 C0.1301,2.102 2.3271,0 5.3301,0"
                id="Fill-6"
              ></path>
              <polygon
                id="Fill-8"
                points="10.1787 0.0976 16.7177 0.0976 16.7177 2.6856 12.8027 2.6856 12.8027 3.6736 16.3597 3.6736 16.3597 6.2616 12.8027 6.2616 12.8027 7.2636 16.8157 7.2636 16.8157 9.8526 10.1787 9.8526"
              ></polygon>
              <polygon
                id="Fill-10"
                points="26.8907 13.8317 33.4297 13.8317 33.4297 16.4197 29.5147 16.4197 29.5147 17.4077 33.0717 17.4077 33.0717 19.9957 29.5147 19.9957 29.5147 20.9977 33.5277 20.9977 33.5277 23.5867 26.8907 23.5867"
              ></polygon>
              <path
                d="M22.1138,7.208 C23.1668,7.208 23.8428,6.359 23.8428,4.996 C23.8428,3.618 23.1018,2.741 21.9708,2.741 L21.0478,2.741 L21.0478,7.208 L22.1138,7.208 Z M17.9018,0.098 L22.0358,0.098 C25.0648,0.098 27.0538,2.004 27.0538,4.968 C27.0538,7.932 25.0258,9.853 21.9188,9.853 L17.9018,9.853 L17.9018,0.098 Z"
                id="Fill-12"
              ></path>
              <polygon
                id="Fill-14"
                points="27.959 0.0976 34.526 0.0976 34.526 2.6856 30.594 2.6856 30.594 3.6736 34.167 3.6736 34.167 6.2616 30.594 6.2616 30.594 7.2636 34.624 7.2636 34.624 9.8526 27.959 9.8526"
              ></polygon>
              <polygon
                id="Fill-16"
                points="35.5762 0.0976 38.1892 0.0976 41.7002 4.9676 41.7002 0.0976 44.5592 0.0976 44.5592 9.8526 41.9462 9.8526 38.4492 5.0096 38.4492 9.8526 35.5762 9.8526"
              ></polygon>
              <polygon
                id="Fill-18"
                points="45.7291 0.0976 48.8751 0.0976 48.8751 3.3676 51.5021 0.0976 55.1421 0.0976 51.8261 4.0356 55.3111 9.8526 51.5541 9.8526 49.6821 6.5396 48.8751 7.5146 48.8751 9.8526 45.7291 9.8526"
              ></polygon>
              <polygon
                id="Fill-20"
                points="0 13.8317 8.333 13.8317 8.333 16.5177 5.733 16.5177 5.733 23.5867 2.587 23.5867 2.587 16.5177 0 16.5177"
              ></polygon>
              <path
                d="M13.312,19.8298 L12.467,17.2558 L11.596,19.8298 L13.312,19.8298 Z M10.972,13.8318 L14.144,13.8318 L17.901,23.5868 L14.547,23.5868 L14.092,22.2098 L10.777,22.2098 L10.309,23.5868 L7.059,23.5868 L10.972,13.8318 Z"
                id="Fill-22"
              ></path>
              <polygon
                id="Fill-24"
                points="18.2647 13.8317 25.7267 13.8317 25.7267 16.4207 21.4107 16.4207 21.4107 17.8947 25.4537 17.8947 25.4537 20.4837 21.4107 20.4837 21.4107 23.5867 18.2647 23.5867"
              ></polygon>
              <polygon
                id="Fill-26"
                points="34.6243 13.8317 37.7703 13.8317 37.7703 20.7627 41.5663 20.7627 41.5663 23.5867 34.6243 23.5867"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
