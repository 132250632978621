//
// DeepLink.js
// tablicepamieci-web
//
// Copyright © 2020 CodeAgency.pl Sp. z o.o.
// All rights reserved.
//
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
//

/* eslint-disable no-restricted-globals */
const NativeAppLauncher = (() => {
  let Settings = {};

  const AppLaunchStrategyParameters = {
    getAppUri() {
      return "#";
    },
    getAppLauncherEl() {
      if (!Settings.appLauncherElId) {
        throw new Error("Settings does not have valid appLauncherElId");
      }
      return document.getElementById(Settings.appLauncherElId);
    },
    getNotSupportedMessage() {
      if (!Settings.appLauncherElId) {
        throw new Error("Settings does not have valid NotSupportedMessage");
      }
      return Settings.notSupportedMessage;
    },
    getCampaignValue() {
      return Settings.campaignCode;
    },
    getAppStoreURI() {
      return "#";
    },
  };

  const AndroidAppLaunchStrategyParameters = {
    ...AppLaunchStrategyParameters,
    getIntentURI() {
      return `intent://m/#Intent;scheme=${Settings.appUri};package=${Settings.androidAppId};end`;
    },
    getAppUri() {
      return Settings.appUri;
    },
    getAppStoreURI() {
      return `https://play.google.com/store/apps/details?id=${Settings.androidAppId}`;
    },
  };

  const IOSAppLaunchStrategyParameters = {
    ...AppLaunchStrategyParameters,
    getAppStoreURI() {
      return this.getCampaignValue()
        ? Utils.appendQueryParameter(
            Settings.iOsAppStore,
            "ct",
            this.getCampaignValue()
          )
        : Settings.iOsAppStore;
    },
    getUniversalLinkingUrl() {
      return Settings.universalLinkUrl;
    },
    getAppUri() {
      if (!Settings.appUri) {
        throw new Error("Settings does not have valid AppURI");
      }
      return Settings.appUri;
    },
  };

  const AppLaunchStrategy = (strategyParameters) => {
    this.strategyParameters = strategyParameters;
    this.init = () => {};
  };

  const DirectAppOnlyLaunchStrategy = (strategyParameters) => {
    this.init = () => {
      const el = strategyParameters.getAppLauncherEl();
      const appURI = strategyParameters.getAppUri();
      el.click((e) => {
        e.preventDefault();
        window.location.reload();
      });
      window.location = appURI;
    };
  };

  const DirectAppLaunchStrategy = (strategyParameters) => {
    AppLaunchStrategy.call(this, strategyParameters);
    const events = ["pagehide", "blur", "beforeunload"];
    let timeout = null;
    const preventDialog = () => {
      clearTimeout(timeout);
      timeout = null;
      window.removeEventListener(events.join(" "));
    };

    const el = strategyParameters.getAppLauncherEl();

    this.init = () => {
      redirect();
      const elClone = el.cloneNode(true);
      el.parentNode.replaceChild(elClone, el);
      el.addEventListener("click", (e) => {
        e.preventDefault();
        redirect();
      });
    };

    const redirect = () => {
      window.location = strategyParameters.getAppUri();
      window.addEventListener(events.join(" "), preventDialog);

      timeout = setTimeout(() => {
        window.top.location = strategyParameters.getAppStoreURI();
      }, 1500);
    };
  };

  const CTAAppLaunchStrategy = (strategyParameters) => {
    AppLaunchStrategy.call(this, strategyParameters);

    this.init = () => {
      const directStrategy = new DirectAppLaunchStrategy(strategyParameters);
      const el = strategyParameters.getAppLauncherEl();
      const { id } = el;
      document.getElementById(id).addEventListener("click", (e) => {
        e.preventDefault();
        directStrategy.init();
      });
    };
  };

  const CTAIntentAppLaunchStrategy = (strategyParameters) => {
    AppLaunchStrategy.call(this, strategyParameters);

    this.init = () => {
      const el = strategyParameters.getAppLauncherEl();
      el.setAttribute("href", strategyParameters.getIntentURI());
    };
  };

  const AppLaunchNotSupportedStrategy = (strategyParameters) => {
    AppLaunchStrategy.call(this, strategyParameters);
    this.init = () => {
      const { id } = strategyParameters.getAppLauncherEl();
      document.getElementById(id).addEventListener("click", (e) => {
        e.preventDefault();
      });
    };
  };

  const UniversalLinkingAppLaunchStrategy = (strategyParameters) => {
    AppLaunchStrategy.call(this, strategyParameters);

    this.init = () => {
      if (!strategyParameters.getUniversalLinkingUrl()) {
        console.log(
          `Error: Universal Linking: Invalid url provided: ${strategyParameters.getUniversalLinkingUrl()}`
        );
      }
      const el = strategyParameters.getAppLauncherEl();

      const $cookieName = "ul-app-detection-flag";

      const $location = strategyParameters.getUniversalLinkingUrl();

      if (appNotInstalled()) {
        window.location = strategyParameters.getAppStoreURI();
        eraseCookie();
      }
      el.setAttribute("href", $location);

      window.addEventListener("blur", () => eraseCookie($cookieName));

      setCookie();
      el.click(() => {
        setCookie();
      });

      function appNotInstalled() {
        return readCookie($cookieName) > 1;
      }

      function setCookie() {
        const $cookieValue = readCookie($cookieName);
        if (!$cookieValue || isNaN($cookieValue)) {
          createCookie($cookieName, 1, 60);
        } else {
          createCookie($cookieName, 2, 60);
        }
      }
    };

    function createCookie(name, value, second) {
      const date = new Date();
      date.setTime(date.getTime() + second * 1000);
      const expires = `; expires=${date.toGMTString()}`;
      document.cookie = `${name}=${value}${expires}; path=/`;
    }

    function readCookie(name) {
      const nameEQ = `${name}=`;
      const ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
          return c.substring(nameEQ.length, c.length);
      }
      return null;
    }

    function eraseCookie(name) {
      createCookie(name, "", -1);
    }
  };

  const AppLaunchStrategyParameterFactory = () => {
    let strategyParameters = AppLaunchStrategyParameters;
    let parameterType;

    if (BrowserChecker().isIOS) {
      parameterType = "ios";
      strategyParameters = IOSAppLaunchStrategyParameters;
    } else if (BrowserChecker().isAndroid) {
      parameterType = "android";
      strategyParameters = AndroidAppLaunchStrategyParameters;
    } else {
      parameterType = "desktop or default";
      strategyParameters = AppLaunchStrategyParameters;
    }

    if (Settings.debug && strategyParameters) {
      __debug("AppLaunchParameter", parameterType);
    }
    return strategyParameters;
  };

  const AppLaunchStrategyFactory = (strategyType) => {
    const strategyParameters = AppLaunchStrategyParameterFactory();
    let appLaunchStrategy;

    if (strategyType === "cta" || strategyType == null) {
      appLaunchStrategy = new CTAAppLaunchStrategy(strategyParameters);
    } else if (strategyType === "direct") {
      appLaunchStrategy = new DirectAppLaunchStrategy(strategyParameters);
    } else if (strategyType === "ul") {
      appLaunchStrategy = new UniversalLinkingAppLaunchStrategy(
        strategyParameters
      );
    } else if (strategyType === "notsupported") {
      appLaunchStrategy = new AppLaunchNotSupportedStrategy(strategyParameters);
    } else if (strategyType === "directapponly") {
      appLaunchStrategy = new DirectAppOnlyLaunchStrategy(strategyParameters);
    } else if (strategyType === "intent_cta") {
      appLaunchStrategy = new CTAIntentAppLaunchStrategy(strategyParameters);
    } else {
      throw new Error("Deeplinking: Unsupported deeplinking strategy type");
    }

    if (Settings.debug && appLaunchStrategy) {
      __debug("AppLaunchStrategyType", strategyType);
    }
    return appLaunchStrategy;
  };

  const BrowserChecker = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const iOSVersion = () =>
      isIOS() ? parseInt(userAgent.match(/os\s+(\d+)_/)[1], 10) : false;
    const isIOS = () => /(?:i(?:phone|p(?:o|a)d))/.test(userAgent);

    const isFacebook = () => !!userAgent.match(/FBAV/i);

    const isChrome = () => userAgent.includes("chrome");

    const isTwitter = () => userAgent.includes("twitter");

    const isAndroid = () => userAgent.includes("android");

    const androidVersion = () => {
      const match = userAgent.match(/android\s([0-9.]*)/);
      return match ? parseFloat(match[1]) : false;
    };

    const isAndroidStockBrowser = () =>
      isAndroid() && isChrome() && hasVersion() && !isFacebook();

    const isAndroidNativeBrowser = () =>
      !isFacebook() &&
      isAndroid() &&
      appleWebKitVersion() &&
      appleWebKitVersion() < 537;

    const hasVersion = () => userAgent.includes("version");

    const appleWebKitVersion = () => {
      const match = userAgent.match(/AppleWebKit\/([\d.]+)/);
      return match ? parseFloat(match[1]) : false;
    };

    return {
      isIOS: isIOS(),
      iOSVersion: iOSVersion(),
      isAndroid: isAndroid(),
      androidVersion: androidVersion(),
      isAndroidStockBrowser: isAndroidStockBrowser(),
      isAndroidNativeBrowser: isAndroidNativeBrowser(),
      isFacebook: isFacebook(),
      isChrome: isChrome(),
      isTwitter: isTwitter(),
    };
  };

  const AppLauncherFactory = () => {
    const browser = BrowserChecker();
    let deepLinkingStrategy = new AppLaunchStrategyFactory("cta");

    if (browser.isIOS) {
      if (browser.iOSVersion < 9) {
        deepLinkingStrategy = new AppLaunchStrategyFactory("direct");
        if (browser.isTwitter) {
          deepLinkingStrategy = new AppLaunchStrategyFactory("directapponly");
        }
      } else {
        deepLinkingStrategy = new AppLaunchStrategyFactory("direct");
        if (browser.isFacebook) {
          deepLinkingStrategy = new AppLaunchStrategyFactory("ul");
        }
      }
    } else if (browser.isAndroid) {
      deepLinkingStrategy = new AppLaunchStrategyFactory("intent_cta");
      if (browser.isAndroidNativeBrowser || browser.isAndroidStockBrowser) {
        deepLinkingStrategy = new AppLaunchStrategyFactory("notsupported");
      }
    }

    if (Settings.debug) {
      __debug("browser", browser);
    }
    return deepLinkingStrategy;
  };

  const Init = (settings) => {
    Settings = settings;
    setTimeout(() => AppLauncherFactory().init(), 1000);
  };

  function __debug(name, object) {
    console.log(`${name}:${JSON.stringify(object, null, 4)}`);
  }

  const Utils = {
    getQueryString(queryString) {
      if (queryString == null) {
        queryString = window.location.search;
      }

      queryString = queryString.split("+").join(" ");
      const params = {};
      let tokens;
      const re = /[?&]?([^=]+)=([^&]*)/g;
      // eslint-disable-next-line no-cond-assign
      while ((tokens = re.exec(queryString))) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
      }
      return params;
    },
    appendQueryParameter(url, key, value) {
      const re = new RegExp(`([?&])${key}=.*?(&|$)`, "i");
      const separator = url.includes("?") ? "&" : "?";
      if (url.match(re)) {
        return url.replace(re, `$1${key}=${value}$2`);
      }
      return `${url + separator + key}=${value}`;
    },
  };

  return {
    init: Init,
    browserChecker: BrowserChecker,
    util: Utils,
    androidParameters: AndroidAppLaunchStrategyParameters,
    iOSParameters: IOSAppLaunchStrategyParameters,
  };
})();

export default NativeAppLauncher;
