import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { SRLWrapper } from "simple-react-lightbox";
import Iframe from "react-iframe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";

import { useWindowSize } from "../../hooks/useWindowSize";
import CircleImage from "../molecules/CircleImage";
import { AspectRatioElement } from "../atoms/AspectRatioContainer";

const languageCode = {
  pl: "pl_PL",
  en: "en_EN",
  de: "de_DE",
};

const ModalMapElement = ({ data, isOpen }) => {
  const { t, i18n } = useTranslation();
  const { id, photos, street, infos, victims } = data;
  const coordinates = data && JSON.parse(data.coordinates);

  const [showAllVictims, setShowAllVictims] = useState(false);

  const windowSize = useWindowSize();

  const getDescription = () => {
    if (infos.items) {
      const [description] = infos.items.filter(
        (item) => item.language === languageCode[i18n.language]
      );

      if (description) return description.content;
      return t("No content");
    } else return t("No content");
  };

  const navigationLink = coordinates
    ? `https://www.google.com/maps/dir/Current+Location/${coordinates.lat},${coordinates.lon}`
    : "";

  // Bottom modal
  const markerShortDescription = useRef(null);
  const description = useRef(null);

  const heightMarkerShortDescriptionDiv = 160;

  let fullDescriptionOpen = false;

  useEffect(() => {
    setShowAllVictims(false);
  }, [isOpen, id]);

  useEffect(() => {
    if (windowSize.width <= 800) {
      let markerDescriptionDiv;
      let touchStart;
      let actualHeightMarkerDescription;
      let activeMove = false;
      console.log(markerShortDescription);

      markerShortDescription.current.addEventListener("touchstart", (e) => {
        markerDescriptionDiv = description.current;
        actualHeightMarkerDescription = markerDescriptionDiv.clientHeight;
        touchStart = e.touches[0].clientY;

        if (actualHeightMarkerDescription === 160) {
          // eslint-disable-next-line
          fullDescriptionOpen = false;
          markerDescriptionDiv.style.overflow = "hidden";
          setTimeout(() => {
            markerDescriptionDiv.style.overflow = "scroll";
          }, 500);
        }
      });

      markerShortDescription.current.addEventListener("touchend", () => {
        activeMove = false;
      });

      markerShortDescription.current.addEventListener("touchmove", (e) => {
        const touchMove = e.changedTouches[0].clientY;
        const scrollTopOfDescription = markerDescriptionDiv.scrollTop;

        if (!activeMove) {
          if (touchStart > touchMove + 100 && !fullDescriptionOpen) {
            fullDescriptionOpen = true;
            activeMove = true;
            onShortDescriptionMove();
          } else if (
            touchStart < touchMove - 10 &&
            fullDescriptionOpen &&
            scrollTopOfDescription === 0
          ) {
            fullDescriptionOpen = false;
            onShortDescriptionMove();
          }
        }
      });
    }
  }, [windowSize.width]);

  const onShortDescriptionMove = () => {
    if (markerShortDescription.current.style.height === "70vh") {
      markerShortDescription.current.style.height = `${heightMarkerShortDescriptionDiv}px`;
      markerShortDescription.current.style.transform = "translateY(0)";
    } else {
      markerShortDescription.current.style.height = "70vh";
    }
  };

  const getVictimsArray = () => {
    if (showAllVictims) {
      return victims;
    }
    return victims.slice(0, 5);
  };

  useEffect(() => {
    if (windowSize.width <= 800) {
      if (isOpen) {
        markerShortDescription.current.style.transform = "translate(0)";
      }
    }
    // eslint-disable-next-line
  }, [isOpen]);

  let videoLink;
  if (id === "a1d656d9-fa9b-4848-8715-79322d7b9471")
    videoLink = "https://www.youtube.com/embed/hyraFFErxI8";
  else if (id === "7ecda3fa-8c86-4274-a3cf-5b40fdc5e6b4")
    videoLink = "https://www.youtube.com/embed/7NjY1hJF2pI";
  else videoLink = "https://www.youtube.com/embed/wVi7Jmxm0X8";

  if (windowSize.width > 800) {
    return (
      <MainBox isOpen={isOpen}>
        {isOpen && (
          <>
            {photos ? (
              <Image
                src={`https://tablicepamieci.s3.eu-central-1.amazonaws.com/places/${photos[0]}.jpg`}
              />
            ) : (
              <Skeleton height={220} />
            )}
            <ContentBox>
              <Title>{t("Memorial plaque")}</Title>
              <Street>
                <IconeBox>
                  <FontAwesomeIcon icon={faLocationArrow} size="xs" />
                </IconeBox>
                {`ul. ${street}`}
              </Street>
              <Button
                href={navigationLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("navigate")}
              </Button>
              <SmallTitle>{t("Description")}</SmallTitle>
              <Paragraph>{infos && getDescription()}</Paragraph>
              <SmallTitle>{t("Images")}</SmallTitle>
              <SmallParagraph>{`${t("No comments")}.`}</SmallParagraph>
              <SRLWrapper>
                <ImagesList>
                  {photos &&
                    photos.map((photo) => (
                      <ImageLink
                        key={photo}
                        href={`https://tablicepamieci.s3.eu-central-1.amazonaws.com/places/${photo}.jpg`}
                      >
                        <ImageIcon
                          src={`https://tablicepamieci.s3.eu-central-1.amazonaws.com/places/${photo}.jpg`}
                          alt="Tablica pamięci"
                        />
                      </ImageLink>
                    ))}
                </ImagesList>
              </SRLWrapper>

              {victims && victims.length > 0 && (
                <>
                  <SmallTitle>{t("VictimsHeader")}</SmallTitle>
                  {getVictimsArray().map((victim) => (
                    <VictimsBox>
                      <VictimsName>{"✞ " + victim.name}</VictimsName>
                      <VictimsDesc>
                        {victim.date && `${"(" + victim.date + ")"}`}{" "}
                        {victim.date && victim.position && `, `}
                        {victim.position && `${victim.position}`}
                      </VictimsDesc>
                    </VictimsBox>
                  ))}
                  {victims.length > 5 && (
                    <ShowMoreText
                      onClick={() => setShowAllVictims(!showAllVictims)}
                    >
                      {showAllVictims ? "Pokaż mniej" : "Pokaż więcej"}
                    </ShowMoreText>
                  )}
                </>
              )}

              <SmallTitle>{t("Movies")}</SmallTitle>
              <AspectRatioElement>
                <IframeElement src={videoLink} />
              </AspectRatioElement>
              <SmallTitle>{t("Comments")}</SmallTitle>
              <SmallParagraph>{`${t("No comments")}.`}</SmallParagraph>
            </ContentBox>
          </>
        )}
      </MainBox>
    );
  } else {
    return (
      <DescriptionModal ref={markerShortDescription}>
        <ImageBox>
          {photos && (
            <CircleImage
              url={`https://tablicepamieci.s3.eu-central-1.amazonaws.com/places/${photos[0]}.jpg`}
            />
          )}
        </ImageBox>
        <Description ref={description}>
          <Title>{t("Memorial plaque")}</Title>
          <Street>
            <IconeBox>
              <FontAwesomeIcon icon={faLocationArrow} size="xs" />
            </IconeBox>
            {`ul. ${street}`}
          </Street>
          <Button
            href={navigationLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("navigate")}
          </Button>
          <SmallTitle>{t("Description")}</SmallTitle>
          <Paragraph>{infos && getDescription()}</Paragraph>
          <SmallTitle>{t("Images")}</SmallTitle>
          <SRLWrapper>
            <ImagesList>
              {photos &&
                photos.map((photo) => (
                  <ImageLink
                    key={photo}
                    href={`https://tablicepamieci.s3.eu-central-1.amazonaws.com/places/${photo}.jpg`}
                  >
                    <ImageIcon
                      src={`https://tablicepamieci.s3.eu-central-1.amazonaws.com/places/${photo}.jpg`}
                      alt="Tablica pamięci"
                    />
                  </ImageLink>
                ))}
            </ImagesList>
          </SRLWrapper>

          {victims && victims.length > 0 && (
            <>
              <SmallTitle>{t("VictimsHeader")}</SmallTitle>
              {getVictimsArray().map((victim) => (
                <VictimsBox>
                  <VictimsName>{"✞ " + victim.name}</VictimsName>
                  <VictimsDesc>
                    {victim.date && `${"(" + victim.date + ")"}`}{" "}
                    {victim.date && victim.position && `, `}
                    {victim.position && `${victim.position}`}
                  </VictimsDesc>
                </VictimsBox>
              ))}
              {victims.length > 5 && (
                <ShowMoreText
                  onClick={() => setShowAllVictims(!showAllVictims)}
                >
                  {showAllVictims ? "Pokaż mniej" : "Pokaż więcej"}
                </ShowMoreText>
              )}
            </>
          )}
          <SmallTitle>{t("Movies")}</SmallTitle>
          <AspectRatioElement>
            <IframeElement src={videoLink} />
          </AspectRatioElement>
          <SmallTitle>{t("Comments")}</SmallTitle>
          <SmallParagraph>{`${t("No comments")}.`}</SmallParagraph>
        </Description>
      </DescriptionModal>
    );
  }
};

const DescriptionModal = styled.div`
  width: 100%;
  height: 160px;
  position: fixed;
  bottom: 0;
  background-color: white;
  transform: translateY(150%);
  transition: all 0.5s;
  z-index: 3;
  box-shadow: 0 0 20px 0 rgba(10, 16, 21, 0.08);
  box-sizing: border-box;
  overflow: unset;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
`;

const Description = styled.div`
  height: 100%;
  z-index: 1;
  position: relative;
  padding: 20px 10px 0;
  overflow: hidden;
`;

const ImageBox = styled.div`
  position: absolute;
  top: -32px;
  left: 12px;
  z-index: 2;
`;

const MainBox = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 410px;
  height: calc(100vh - 66px);
  transform: translateX(130%);
  background-color: #fff;
  box-shadow: 11px 0px 16px 11px rgba(0, 0, 0, 0.24);
  transition: 0.7s ease-in-out;
  overflow-y: auto;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateX(0);
    `}
`;

const Image = styled.img`
  display: block;
  width: 100%;
  max-height: 220px;
  object-fit: cover;
`;

const IconeBox = styled.div`
  margin-right: 12px;
  display: flex;
  align-items: center;
`;

const ContentBox = styled.div`
  background-color: #fff;
  position: relative;
  padding: 0 12px;
  width: 100%;
`;

const Title = styled.h2`
  margin-top: 30px;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const SmallTitle = styled.h3`
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 19px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const Street = styled.div`
  opacity: 0.54;
  font-size: 16px;
  margin-bottom: 18px;
  display: flex;
`;

const Button = styled.a`
  display: block;
  text-decoration: none;
  color: #fff;
  background-color: ${({ theme }) => theme.blue};
  padding: 8px 30px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: 14px;
  margin-bottom: 28px;
  border-radius: 2px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  border: 1px solid transparent;
  user-select: none;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;

  :hover {
    border-color: ${({ theme }) => theme.blue};
    background-color: transparent;
    color: ${({ theme }) => theme.blue};
  }
`;

const Paragraph = styled.p`
  opacity: 0.54;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 16px;
`;

const SmallParagraph = styled(Paragraph)`
  text-transform: unset;
  font-size: 14px;
  padding-bottom: 16px;
`;

const ImagesList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(65px, 1fr));
  grid-gap: 1px;
`;

const ImageIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const ImageLink = styled.a`
  display: block;
  width: 100%;
  height: 96px;
`;

const IframeElement = styled(Iframe)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const VictimsBox = styled.div`
  margin-top: 10px;
`;
const VictimsName = styled.div`
  line-height: 20px;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const VictimsDesc = styled(Paragraph)`
  text-transform: unset;
`;

const ShowMoreText = styled.div`
  cursor: pointer;
  line-height: 20px;
  font-size: 16px;
  margin-top: 20px;
`;

export default ModalMapElement;
