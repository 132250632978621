import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { API, graphqlOperation } from "aws-amplify";

import Title from "../components/atoms/Title/Title";
import Banner from "../components/molecules/Banner/Banner";

import LayoutTemplate from "../templates/LayoutTemplate";
import HorizontalElementTemplate from "../templates/HorizontalElementTemplate";
import GridTemplate from "../templates/GridTemplate";

import bannerImage from "../images/banner_project.png";
import PlaceWithVictims from "../components/molecules/PlaceWithVictims/PlaceWithVictims";
import AboveTitle from "../components/atoms/AboveTitle/AboveTitle";

const placesByNote = /* GraphQL */ `
  query PlacesByNote(
    $note: String!
    $street: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    placesByNote(
      note: $note
      street: $street
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        street
        victims {
          items {
            id
            placeId
            name
            date
            position
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

const victimsByPlaceId = /* GraphQL */ `
  query VictimsByPlaceId(
    $placeId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVictimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    victimsByPlaceId(
      placeId: $placeId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        placeId
        name
        date
        position
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

const Input = styled.input`
  width: 100%;
  max-width: 1140px;
  height: 50px;
  font-size: 16px;
  align-self: center;
  margin-block: 50px;
  padding-inline: 20px;
  border: 2px solid ${({ theme }) => theme.black300};
  border-radius: 4px;
`;

const TextBox = styled.div`
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  margin-top: 22px;

  p {
    margin: 8px 0;
    font-size: 16px;
    line-height: 1.6;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    color: #4b4b4b;
  }

  h3 {
    margin: 10px 0;
  }

  h4 {
    margin: 5px 0 10px;
  }
`;

const Victims = () => {
  const { t, i18n } = useTranslation();
  const [places, setPlaces] = useState([]);

  const [input, setInput] = useState("");

  const bannerInfo = {
    title: t("VictimsNavHeader"),
    subtitle: t("VictimsBannerContent"),
    image: bannerImage,
  };

  const getPlacesByNote = async (nextToken) => {
    try {
      const {
        data: {
          placesByNote: { items, nextToken: newToken },
        },
      } = await API.graphql(
        graphqlOperation(placesByNote, { note: "V", nextToken })
      );

      if (newToken !== null) {
        return items.concat(await getPlacesByNote(newToken));
      } else {
        return items;
      }
    } catch (e) {
      throw new Error();
    }
  };

  const getVictims = async (placeId, nextToken) => {
    try {
      const {
        data: {
          victimsByPlaceId: { items, nextToken: newToken },
        },
      } = await API.graphql(
        graphqlOperation(victimsByPlaceId, { placeId, nextToken })
      );

      if (newToken !== null) {
        return items.concat(await getVictims(placeId, newToken));
      } else {
        return items;
      }
    } catch (e) {
      throw new Error();
    }
  };

  const getPlacesProcess = async () => {
    const places = await getPlacesByNote();
    const newPlaces = [];

    await Promise.all(
      places.map(async (item) => {
        let victims = [];
        if (item?.victims?.items) {
          victims = [...item.victims.items];
          if (item?.victims?.nextToken) {
            try {
              const missingVictims = await getVictims(
                item.id,
                item.victims.nextToken
              );
              victims = [...victims, ...missingVictims];
            } catch (e) {
              console.log("e: ", e);
            }
          }
        }
        newPlaces.push({ ...item, victims: { items: victims } });
      })
    );
    setPlaces(places);
  };

  const getFilteredPlaces = () => {
    if (input.length > 0) {
      const _places = [];
      places.map((place) => {
        if (place?.victims?.items.length > 0) {
          const filtered = place.victims.items.filter((victim) =>
            victim.name.toLowerCase().includes(input.toLowerCase())
          );
          if (filtered.length > 0) {
            _places.push({ ...place, victims: { items: filtered } });
          } else if (place.street.toLowerCase().includes(input.toLowerCase())) {
            _places.push(place);
          }
        } else if (place.street.toLowerCase().includes(input.toLowerCase())) {
          _places.push(place);
        }
      });
      return _places;
    }
    return places;
  };

  useEffect(() => {
    getPlacesProcess();
  }, []);

  return (
    <>
      <Banner data={bannerInfo} />
      {/* TODO: Treści do ustalenia i przetłumaczenia */}
      <LayoutTemplate>
        <TextBox>
          <AboveTitle>{t("VictimsNavHeader")}</AboveTitle>
          <Content>{t("VictimsContent")}</Content>
        </TextBox>

        <Input onInput={(e) => setInput(e.target.value)} placeholder="Szukaj" />
        <HorizontalElementTemplate>
          <GridTemplate>
            {getFilteredPlaces().map(
              (place) =>
                place.victims &&
                place?.victims?.items.length > 0 && (
                  <PlaceWithVictims place={place} />
                )
            )}
          </GridTemplate>
        </HorizontalElementTemplate>
      </LayoutTemplate>
    </>
  );
};

export default Victims;
