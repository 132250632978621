export const routes = {
  home: "/",
  map: "/map",
  victims: "/ofiary",
  project: "/idea-projektu",
  education: "/edukacja",
  media: "/multimedia",
  mobile: "/aplikacja-mobilna",
  partners: "/partnerzy",
  mobileAppRedirect: "/app",
};
