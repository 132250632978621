import styled from "styled-components";

const ScrollTrigger = styled.a`
  position: absolute;
  left: 50%;
  bottom: 14px;
  width: 30px;
  height: 50px;
  border-radius: 50px;
  border: 2px solid #fff;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-bottom: 20px;

  &::after {
    position: absolute;
    top: 10px;
    left: 50%;
    content: "";
    width: 6px;
    height: 6px;
    margin-left: -3px;
    background-color: #ffffff;
    border-radius: 100%;
    -webkit-animation: dotAnimation 2s infinite;
    animation: dotAnimation 2s infinite;
  }
`;

export default ScrollTrigger;
