import * as React from "react"

export const Qrcode = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
    //   height={999}
      viewBox="0 0 999 999"
      {...props}
    >
      <path fill="#fff" d="M0 0h999v999H0z" />
      <path d="M270 54h27.81v27.81H270zM324 54h27.81v27.81H324zM378 54h27.81v27.81H378z" />
      <path d="M405 54h27.81v27.81H405z" />
      <path d="M432 54h27.81v27.81H432zM513 54h27.81v27.81H513z" />
      <path d="M540 54h27.81v27.81H540z" />
      <path d="M567 54h27.81v27.81H567zM621 54h27.81v27.81H621zM675 54h27.81v27.81H675z" />
      <path d="M702 54h27.81v27.81H702zM270 81h27.81v27.81H270zM324 81h27.81v27.81H324z" />
      <path d="M351 81h27.81v27.81H351zM459 81h27.81v27.81H459z" />
      <path d="M486 81h27.81v27.81H486zM567 81h27.81v27.81H567zM702 81h27.81v27.81H702zM270 108h27.81v27.81H270z" />
      <path d="M297 108h27.81v27.81H297z" />
      <path d="M324 108h27.81v27.81H324zM378 108h27.81v27.81H378z" />
      <path d="M405 108h27.81v27.81H405zM513 108h27.81v27.81H513zM594 108h27.81v27.81H594z" />
      <path d="M621 108h27.81v27.81H621zM675 108h27.81v27.81H675z" />
      <path d="M702 108h27.81v27.81H702zM324 135h27.81v27.81H324zM378 135h27.81v27.81H378zM513 135h27.81v27.81H513z" />
      <path d="M540 135h27.81v27.81H540z" />
      <path d="M567 135h27.81v27.81H567zM648 135h27.81v27.81H648z" />
      <path d="M675 135h27.81v27.81H675zM324 162h27.81v27.81H324z" />
      <path d="M351 162h27.81v27.81H351z" />
      <path d="M378 162h27.81v27.81H378zM432 162h27.81v27.81H432z" />
      <path d="M459 162h27.81v27.81H459zM513 162h27.81v27.81H513zM567 162h27.81v27.81H567zM621 162h27.81v27.81H621zM675 162h27.81v27.81H675zM270 189h27.81v27.81H270zM378 189h27.81v27.81H378z" />
      <path d="M567 189h27.81v27.81H567zM621 189h27.81v27.81H621z" />
      <path d="M648 189h27.81v27.81H648zM702 189h27.81v27.81H702zM270 216h27.81v27.81H270zM324 216h27.81v27.81H324zM378 216h27.81v27.81H378zM432 216h27.81v27.81H432zM486 216h27.81v27.81H486zM540 216h27.81v27.81H540zM594 216h27.81v27.81H594z" />
      <path d="M648 216h27.81v27.81H648zM702 216h27.81v27.81H702zM270 243h27.81v27.81H270z" />
      <path d="M297 243h27.81v27.81H297zM351 243h27.81v27.81H351zM405 243h27.81v27.81H405z" />
      <path d="M432 243h27.81v27.81H432zM513 243h27.81v27.81H513zM567 243h27.81v27.81H567zM621 243h27.81v27.81H621z" />
      <path d="M648 243h27.81v27.81H648zM702 243h27.81v27.81H702zM108 270h27.81v27.81H108z" />
      <path d="M135 270h27.81v27.81H135z" />
      <path d="M162 270h27.81v27.81H162zM216 270h27.81v27.81H216zM270 270h27.81v27.81H270zM324 270h27.81v27.81H324z" />
      <path d="M351 270h27.81v27.81H351z" />
      <path d="M378 270h27.81v27.81H378zM459 270h27.81v27.81H459zM513 270h27.81v27.81H513z" />
      <path d="M540 270h27.81v27.81H540z" />
      <path d="M567 270h27.81v27.81H567z" />
      <path d="M594 270h27.81v27.81H594zM648 270h27.81v27.81H648zM702 270h27.81v27.81H702z" />
      <path d="M729 270h27.81v27.81H729z" />
      <path d="M756 270h27.81v27.81H756z" />
      <path d="M783 270h27.81v27.81H783zM864 270h27.81v27.81H864z" />
      <path d="M891 270h27.81v27.81H891z" />
      <path d="M918 270h27.81v27.81H918zM54 297h27.81v27.81H54z" />
      <path d="M81 297h27.81v27.81H81zM135 297h27.81v27.81H135z" />
      <path d="M162 297h27.81v27.81H162zM243 297h27.81v27.81H243z" />
      <path d="M270 297h27.81v27.81H270z" />
      <path d="M297 297h27.81v27.81H297z" />
      <path d="M324 297h27.81v27.81H324z" />
      <path d="M351 297h27.81v27.81H351zM405 297h27.81v27.81H405zM513 297h27.81v27.81H513zM567 297h27.81v27.81H567zM621 297h27.81v27.81H621zM675 297h27.81v27.81H675zM729 297h27.81v27.81H729z" />
      <path d="M756 297h27.81v27.81H756zM864 297h27.81v27.81H864z" />
      <path d="M891 297h27.81v27.81H891z" />
      <path d="M918 297h27.81v27.81H918zM81 324h27.81v27.81H81z" />
      <path d="M108 324h27.81v27.81H108z" />
      <path d="M135 324h27.81v27.81H135z" />
      <path d="M162 324h27.81v27.81H162z" />
      <path d="M189 324h27.81v27.81H189z" />
      <path d="M216 324h27.81v27.81H216zM297 324h27.81v27.81H297z" />
      <path d="M324 324h27.81v27.81H324zM378 324h27.81v27.81H378z" />
      <path d="M405 324h27.81v27.81H405z" />
      <path d="M432 324h27.81v27.81H432zM513 324h27.81v27.81H513zM594 324h27.81v27.81H594z" />
      <path d="M621 324h27.81v27.81H621zM729 324h27.81v27.81H729z" />
      <path d="M756 324h27.81v27.81H756z" />
      <path d="M783 324h27.81v27.81H783zM837 324h27.81v27.81H837zM891 324h27.81v27.81H891zM54 351h27.81v27.81H54zM189 351h27.81v27.81H189zM243 351h27.81v27.81H243zM378 351h27.81v27.81H378zM459 351h27.81v27.81H459zM621 351h27.81v27.81H621zM702 351h27.81v27.81H702z" />
      <path d="M756 351h27.81v27.81H756zM837 351h27.81v27.81H837z" />
      <path d="M864 351h27.81v27.81H864z" />
      <path d="M891 351h27.81v27.81H891z" />
      <path d="M918 351h27.81v27.81H918zM54 378h27.81v27.81H54zM108 378h27.81v27.81H108z" />
      <path d="M135 378h27.81v27.81H135zM216 378h27.81v27.81H216zM270 378h27.81v27.81H270z" />
      <path d="M297 378h27.81v27.81H297zM432 378h27.81v27.81H432z" />
      <path d="M459 378h27.81v27.81H459z" />
      <path d="M486 378h27.81v27.81H486zM594 378h27.81v27.81H594z" />
      <path d="M621 378h27.81v27.81H621zM675 378h27.81v27.81H675z" />
      <path d="M702 378h27.81v27.81H702z" />
      <path d="M729 378h27.81v27.81H729zM783 378h27.81v27.81H783z" />
      <path d="M810 378h27.81v27.81H810zM891 378h27.81v27.81H891zM54 405h27.81v27.81H54zM135 405h27.81v27.81H135zM243 405h27.81v27.81H243z" />
      <path d="M270 405h27.81v27.81H270z" />
      <path d="M297 405h27.81v27.81H297z" />
      <path d="M324 405h27.81v27.81H324z" />
      <path d="M351 405h27.81v27.81H351zM432 405h27.81v27.81H432zM540 405h27.81v27.81H540zM675 405h27.81v27.81H675zM729 405h27.81v27.81H729z" />
      <path d="M756 405h27.81v27.81H756z" />
      <path d="M783 405h27.81v27.81H783zM837 405h27.81v27.81H837zM918 405h27.81v27.81H918zM54 432h27.81v27.81H54zM108 432h27.81v27.81H108z" />
      <path d="M135 432h27.81v27.81H135z" />
      <path d="M162 432h27.81v27.81H162zM216 432h27.81v27.81H216zM270 432h27.81v27.81H270z" />
      <path d="M297 432h27.81v27.81H297zM405 432h27.81v27.81H405z" />
      <path d="M432 432h27.81v27.81H432z" />
      <path d="M459 432h27.81v27.81H459zM540 432h27.81v27.81H540zM594 432h27.81v27.81H594zM648 432h27.81v27.81H648zM783 432h27.81v27.81H783zM891 432h27.81v27.81H891zM54 459h27.81v27.81H54zM108 459h27.81v27.81H108zM189 459h27.81v27.81H189zM297 459h27.81v27.81H297z" />
      <path d="M324 459h27.81v27.81H324zM432 459h27.81v27.81H432z" />
      <path d="M459 459h27.81v27.81H459z" />
      <path d="M486 459h27.81v27.81H486z" />
      <path d="M513 459h27.81v27.81H513z" />
      <path d="M540 459h27.81v27.81H540z" />
      <path d="M567 459h27.81v27.81H567zM621 459h27.81v27.81H621z" />
      <path d="M648 459h27.81v27.81H648zM702 459h27.81v27.81H702z" />
      <path d="M729 459h27.81v27.81H729zM810 459h27.81v27.81H810z" />
      <path d="M837 459h27.81v27.81H837z" />
      <path d="M864 459h27.81v27.81H864z" />
      <path d="M891 459h27.81v27.81H891zM54 486h27.81v27.81H54z" />
      <path d="M81 486h27.81v27.81H81z" />
      <path d="M108 486h27.81v27.81H108z" />
      <path d="M135 486h27.81v27.81H135zM189 486h27.81v27.81H189z" />
      <path d="M216 486h27.81v27.81H216zM324 486h27.81v27.81H324z" />
      <path d="M351 486h27.81v27.81H351zM405 486h27.81v27.81H405z" />
      <path d="M432 486h27.81v27.81H432z" />
      <path d="M459 486h27.81v27.81H459z" />
      <path d="M486 486h27.81v27.81H486zM540 486h27.81v27.81H540z" />
      <path d="M567 486h27.81v27.81H567zM729 486h27.81v27.81H729zM783 486h27.81v27.81H783z" />
      <path d="M810 486h27.81v27.81H810z" />
      <path d="M837 486h27.81v27.81H837zM81 513h27.81v27.81H81z" />
      <path d="M108 513h27.81v27.81H108z" />
      <path d="M135 513h27.81v27.81H135z" />
      <path d="M162 513h27.81v27.81H162zM297 513h27.81v27.81H297zM432 513h27.81v27.81H432zM486 513h27.81v27.81H486z" />
      <path d="M513 513h27.81v27.81H513z" />
      <path d="M540 513h27.81v27.81H540z" />
      <path d="M567 513h27.81v27.81H567zM675 513h27.81v27.81H675z" />
      <path d="M702 513h27.81v27.81H702zM756 513h27.81v27.81H756zM918 513h27.81v27.81H918zM81 540h27.81v27.81H81z" />
      <path d="M108 540h27.81v27.81H108z" />
      <path d="M135 540h27.81v27.81H135zM189 540h27.81v27.81H189z" />
      <path d="M216 540h27.81v27.81H216z" />
      <path d="M243 540h27.81v27.81H243z" />
      <path d="M270 540h27.81v27.81H270zM324 540h27.81v27.81H324zM378 540h27.81v27.81H378z" />
      <path d="M405 540h27.81v27.81H405zM459 540h27.81v27.81H459zM513 540h27.81v27.81H513zM594 540h27.81v27.81H594z" />
      <path d="M621 540h27.81v27.81H621z" />
      <path d="M648 540h27.81v27.81H648zM729 540h27.81v27.81H729z" />
      <path d="M756 540h27.81v27.81H756z" />
      <path d="M783 540h27.81v27.81H783zM864 540h27.81v27.81H864zM108 567h27.81v27.81H108zM162 567h27.81v27.81H162z" />
      <path d="M189 567h27.81v27.81H189zM243 567h27.81v27.81H243zM297 567h27.81v27.81H297zM378 567h27.81v27.81H378zM432 567h27.81v27.81H432zM567 567h27.81v27.81H567z" />
      <path d="M594 567h27.81v27.81H594z" />
      <path d="M621 567h27.81v27.81H621zM675 567h27.81v27.81H675z" />
      <path d="M702 567h27.81v27.81H702z" />
      <path d="M729 567h27.81v27.81H729z" />
      <path d="M756 567h27.81v27.81H756z" />
      <path d="M783 567h27.81v27.81H783zM837 567h27.81v27.81H837z" />
      <path d="M864 567h27.81v27.81H864z" />
      <path d="M891 567h27.81v27.81H891zM81 594h27.81v27.81H81zM162 594h27.81v27.81H162z" />
      <path d="M189 594h27.81v27.81H189z" />
      <path d="M216 594h27.81v27.81H216z" />
      <path d="M243 594h27.81v27.81H243z" />
      <path d="M270 594h27.81v27.81H270z" />
      <path d="M297 594h27.81v27.81H297z" />
      <path d="M324 594h27.81v27.81H324z" />
      <path d="M351 594h27.81v27.81H351zM405 594h27.81v27.81H405z" />
      <path d="M432 594h27.81v27.81H432z" />
      <path d="M459 594h27.81v27.81H459z" />
      <path d="M486 594h27.81v27.81H486zM540 594h27.81v27.81H540z" />
      <path d="M567 594h27.81v27.81H567z" />
      <path d="M594 594h27.81v27.81H594zM648 594h27.81v27.81H648zM729 594h27.81v27.81H729zM783 594h27.81v27.81H783z" />
      <path d="M810 594h27.81v27.81H810z" />
      <path d="M837 594h27.81v27.81H837zM918 594h27.81v27.81H918zM54 621h27.81v27.81H54zM189 621h27.81v27.81H189zM297 621h27.81v27.81H297z" />
      <path d="M324 621h27.81v27.81H324zM378 621h27.81v27.81H378zM486 621h27.81v27.81H486zM540 621h27.81v27.81H540z" />
      <path d="M567 621h27.81v27.81H567z" />
      <path d="M594 621h27.81v27.81H594z" />
      <path d="M621 621h27.81v27.81H621zM675 621h27.81v27.81H675zM756 621h27.81v27.81H756z" />
      <path d="M783 621h27.81v27.81H783zM837 621h27.81v27.81H837z" />
      <path d="M864 621h27.81v27.81H864zM918 621h27.81v27.81H918zM54 648h27.81v27.81H54zM135 648h27.81v27.81H135zM189 648h27.81v27.81H189z" />
      <path d="M216 648h27.81v27.81H216zM297 648h27.81v27.81H297z" />
      <path d="M324 648h27.81v27.81H324z" />
      <path d="M351 648h27.81v27.81H351z" />
      <path d="M378 648h27.81v27.81H378zM432 648h27.81v27.81H432zM513 648h27.81v27.81H513zM810 648h27.81v27.81H810z" />
      <path d="M837 648h27.81v27.81H837zM891 648h27.81v27.81H891zM54 675h27.81v27.81H54zM162 675h27.81v27.81H162zM243 675h27.81v27.81H243z" />
      <path d="M270 675h27.81v27.81H270zM378 675h27.81v27.81H378z" />
      <path d="M405 675h27.81v27.81H405zM540 675h27.81v27.81H540z" />
      <path d="M567 675h27.81v27.81H567z" />
      <path d="M594 675h27.81v27.81H594z" />
      <path d="M621 675h27.81v27.81H621z" />
      <path d="M648 675h27.81v27.81H648zM702 675h27.81v27.81H702zM837 675h27.81v27.81H837z" />
      <path d="M864 675h27.81v27.81H864z" />
      <path d="M891 675h27.81v27.81H891zM54 702h27.81v27.81H54zM135 702h27.81v27.81H135zM216 702h27.81v27.81H216z" />
      <path d="M243 702h27.81v27.81H243z" />
      <path d="M270 702h27.81v27.81H270zM324 702h27.81v27.81H324zM378 702h27.81v27.81H378zM459 702h27.81v27.81H459z" />
      <path d="M486 702h27.81v27.81H486zM594 702h27.81v27.81H594zM648 702h27.81v27.81H648zM702 702h27.81v27.81H702z" />
      <path d="M729 702h27.81v27.81H729z" />
      <path d="M756 702h27.81v27.81H756z" />
      <path d="M783 702h27.81v27.81H783z" />
      <path d="M810 702h27.81v27.81H810z" />
      <path d="M837 702h27.81v27.81H837zM918 702h27.81v27.81H918zM270 729h27.81v27.81H270zM324 729h27.81v27.81H324z" />
      <path d="M351 729h27.81v27.81H351zM405 729h27.81v27.81H405zM540 729h27.81v27.81H540zM702 729h27.81v27.81H702zM810 729h27.81v27.81H810z" />
      <path d="M837 729h27.81v27.81H837z" />
      <path d="M864 729h27.81v27.81H864z" />
      <path d="M891 729h27.81v27.81H891z" />
      <path d="M918 729h27.81v27.81H918zM297 756h27.81v27.81H297z" />
      <path d="M324 756h27.81v27.81H324zM378 756h27.81v27.81H378zM459 756h27.81v27.81H459z" />
      <path d="M486 756h27.81v27.81H486zM567 756h27.81v27.81H567zM648 756h27.81v27.81H648z" />
      <path d="M675 756h27.81v27.81H675z" />
      <path d="M702 756h27.81v27.81H702zM756 756h27.81v27.81H756zM810 756h27.81v27.81H810z" />
      <path d="M837 756h27.81v27.81H837z" />
      <path d="M864 756h27.81v27.81H864z" />
      <path d="M891 756h27.81v27.81H891zM297 783h27.81v27.81H297z" />
      <path d="M324 783h27.81v27.81H324zM432 783h27.81v27.81H432z" />
      <path d="M459 783h27.81v27.81H459zM513 783h27.81v27.81H513z" />
      <path d="M540 783h27.81v27.81H540z" />
      <path d="M567 783h27.81v27.81H567z" />
      <path d="M594 783h27.81v27.81H594zM675 783h27.81v27.81H675z" />
      <path d="M702 783h27.81v27.81H702zM810 783h27.81v27.81H810z" />
      <path d="M837 783h27.81v27.81H837z" />
      <path d="M864 783h27.81v27.81H864z" />
      <path d="M891 783h27.81v27.81H891z" />
      <path d="M918 783h27.81v27.81H918zM270 810h27.81v27.81H270z" />
      <path d="M297 810h27.81v27.81H297z" />
      <path d="M324 810h27.81v27.81H324z" />
      <path d="M351 810h27.81v27.81H351zM540 810h27.81v27.81H540zM621 810h27.81v27.81H621z" />
      <path d="M648 810h27.81v27.81H648zM702 810h27.81v27.81H702z" />
      <path d="M729 810h27.81v27.81H729z" />
      <path d="M756 810h27.81v27.81H756z" />
      <path d="M783 810h27.81v27.81H783z" />
      <path d="M810 810h27.81v27.81H810z" />
      <path d="M837 810h27.81v27.81H837zM891 810h27.81v27.81H891z" />
      <path d="M918 810h27.81v27.81H918zM270 837h27.81v27.81H270z" />
      <path d="M297 837h27.81v27.81H297z" />
      <path d="M324 837h27.81v27.81H324z" />
      <path d="M351 837h27.81v27.81H351zM432 837h27.81v27.81H432z" />
      <path d="M459 837h27.81v27.81H459z" />
      <path d="M486 837h27.81v27.81H486zM540 837h27.81v27.81H540z" />
      <path d="M567 837h27.81v27.81H567z" />
      <path d="M594 837h27.81v27.81H594z" />
      <path d="M621 837h27.81v27.81H621zM675 837h27.81v27.81H675z" />
      <path d="M702 837h27.81v27.81H702z" />
      <path d="M729 837h27.81v27.81H729zM810 837h27.81v27.81H810zM864 837h27.81v27.81H864zM918 837h27.81v27.81H918zM270 864h27.81v27.81H270z" />
      <path d="M297 864h27.81v27.81H297z" />
      <path d="M324 864h27.81v27.81H324z" />
      <path d="M351 864h27.81v27.81H351z" />
      <path d="M378 864h27.81v27.81H378zM432 864h27.81v27.81H432zM486 864h27.81v27.81H486zM540 864h27.81v27.81H540zM594 864h27.81v27.81H594z" />
      <path d="M621 864h27.81v27.81H621z" />
      <path d="M648 864h27.81v27.81H648z" />
      <path d="M675 864h27.81v27.81H675zM729 864h27.81v27.81H729z" />
      <path d="M756 864h27.81v27.81H756zM864 864h27.81v27.81H864zM297 891h27.81v27.81H297z" />
      <path d="M324 891h27.81v27.81H324zM405 891h27.81v27.81H405z" />
      <path d="M432 891h27.81v27.81H432z" />
      <path d="M459 891h27.81v27.81H459zM513 891h27.81v27.81H513z" />
      <path d="M540 891h27.81v27.81H540zM729 891h27.81v27.81H729zM783 891h27.81v27.81H783zM837 891h27.81v27.81H837z" />
      <path d="M864 891h27.81v27.81H864zM297 918h27.81v27.81H297zM432 918h27.81v27.81H432z" />
      <path d="M459 918h27.81v27.81H459zM513 918h27.81v27.81H513zM567 918h27.81v27.81H567z" />
      <path d="M594 918h27.81v27.81H594zM675 918h27.81v27.81H675z" />
      <path d="M702 918h27.81v27.81H702zM891 918h27.81v27.81H891zM54 54v189h189V54H54zm162 162H81V81h135v135zM756 54v189h189V54H756zm162 162H783V81h135v135zM54 756v189h189V756H54zm162 162H81V783h135v135z" />
      <path d="M108 108h81v81h-81zM810 108h81v81h-81zM108 810h81v81h-81z" />
    </svg>
  )
}
