import React from "react";
import styled from "styled-components";

import NavBar from "../components/organisms/NavBar/NavBar";
import Footer from "../components/organisms/Footer";

const Template = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 94%;
  max-width: 1140px;
  background-color: #fff;
  margin: ${({ isHome }) => (isHome ? "8vh auto" : "150px auto")};

  @media (max-width: 991px) {
    margin: 8vh auto;
  }
`;

const LayoutTemplate = ({ children, home }) => {
  return (
    <>
      <NavBar />
      <Template isHome={home}>{children}</Template>
      <Footer />
    </>
  );
};

export default LayoutTemplate;
