import React from "react";
import styled from "styled-components";

const CircleImage = ({ url, ...rest }) => {
  return (
    <Container {...rest}>
      <Image src={url} />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : "64px")};
  height: ${({ height }) => (height ? `${height}px` : "64px")};
  background-color: #f5f5f5;
  border-radius: 50%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  padding: 3px;
`;

export default CircleImage;
