import React from "react";
import styled, { css } from "styled-components";

const Element = styled.div`
  display: flex;
  flex-direction: ${({ index }) => index % 2 !== 0 && "row-reverse"};
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1140px;

  ${({ isHomePage }) =>
    isHomePage
      ? css`
          @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
          }
        `
      : css`
          @media (max-width: 992px) {
            flex-direction: column;
            align-items: flex-start;
          }
        `}
`;

const HorizontalElementTemplate = ({ children, index = 0, isHomePage }) => (
  <Element index={index} isHomePage={isHomePage}>
    {children}
  </Element>
);

export default HorizontalElementTemplate;
