import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";

const PlaceBox = styled.div`
  margin-bottom: 20px;
`;
const PlaceStreet = styled.div`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const PlaceContentBox = styled.div`
  margin-top: 20px;
`;

const PlaceStreetBox = styled.div`
  cursor: pointer;
  display: flex;

  &:hover {
    color: ${({ theme }) => theme.red200};
  }
`;

const IconBox = styled.div`
  margin-left: 12px;
`;

const VictimsBox = styled.div`
  margin-top: 10px;
`;
const VictimsName = styled.div`
  line-height: 20px;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const Paragraph = styled.p`
  opacity: 0.54;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 16px;
`;

const VictimsDesc = styled(Paragraph)`
  text-transform: unset;
`;

const ShowMoreText = styled.div`
  cursor: pointer;
  line-height: 20px;
  font-size: 16px;
  margin-top: 20px;
`;

const PlaceWithVictims = ({ place }) => {
  const [showVictims, setShowVictims] = useState(false);
  const [showAllVictims, setShowAllVictims] = useState(false);

  const getVictimsArray = () => {
    if (showAllVictims) {
      return place.victims.items;
    }
    return place.victims.items.slice(0, 5);
  };

  return (
    <PlaceBox>
      <PlaceStreetBox onClick={() => setShowVictims(!showVictims)}>
        <PlaceStreet>{place.street}</PlaceStreet>
        <IconBox>
          {showVictims ? (
            <FontAwesomeIcon icon={faAngleUp} size="lg" />
          ) : (
            <FontAwesomeIcon icon={faAngleDown} size="lg" />
          )}
        </IconBox>
      </PlaceStreetBox>
      {showVictims && (
        <PlaceContentBox>
          {getVictimsArray().map((victim) => (
            <VictimsBox>
              <VictimsName>{"✞ " + victim.name}</VictimsName>
              <VictimsDesc>
                {victim.date && `${"(" + victim.date + ")"}`}{" "}
                {victim.date && victim.position && `, `}
                {victim.position && `${victim.position}`}
              </VictimsDesc>
            </VictimsBox>
          ))}

          {place.victims.items.length > 5 && (
            <ShowMoreText onClick={() => setShowAllVictims(!showAllVictims)}>
              {showAllVictims ? "Pokaż mniej" : "Pokaż więcej"}
            </ShowMoreText>
          )}
        </PlaceContentBox>
      )}
    </PlaceBox>
  );
};

export default PlaceWithVictims;
