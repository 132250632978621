import styled, { css } from "styled-components";

const Title = styled.h3`
  color: ${({ theme }) => theme.font400};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-family: ${({ theme }) => theme.poppins};
  margin-bottom: 8px;

  ::first-letter {
    text-transform: uppercase;
  }

  ${({ big }) =>
    big &&
    css`
      font-size: ${({ theme }) => theme.fontSize.xxl};
    `}
`;

export default Title;
