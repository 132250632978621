import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import { API, graphqlOperation } from "aws-amplify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

import MapTemplate from "../templates/MapTemplate";
import NavBar from "../components/organisms/NavBar/NavBar";
import ModalMapElement from "../components/map/ModalMapElement";
import NativeAppLauncher from "../components/DeepLink";
import { useGetSearchParameters } from "../hooks/useGetSearchParameters";

// import postImage from "../images/poczta_polska_1.png";
import activePostImage from "../images/poczta_polska_2.png";

const listPlaces = /* GraphQL */ `
  query ListPlaces($limit: Int) {
    listPlaces(limit: $limit) {
      items {
        id
        number
        coordinates
        infos {
          items {
            id
            placeId
            language
            content
          }
          nextToken
        }
      }
    }
  }
`;

const getPlaceByNumber = /* GraphQL */ `
  query GetPlaceByNumber(
    $number: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelPlaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPlaceByNumber(
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        street
        coordinates
        featured
        qrs
        photos
        movies
        infos {
          items {
            id
            placeId
            language
            content
          }
          nextToken
        }
        comments {
          items {
            id
            placeId
            name
            content
            accepted
          }
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;

const victimsByPlaceId = /* GraphQL */ `
  query VictimsByPlaceId(
    $placeId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVictimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    victimsByPlaceId(
      placeId: $placeId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        placeId
        name
        date
        position
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

const postOfficePlacesId = [
  "a1d656d9-fa9b-4848-8715-79322d7b9471",
  "7ecda3fa-8c86-4274-a3cf-5b40fdc5e6b4",
];

const initialValues = {
  center: { lat: 52.233446, lng: 21.009757 },
  zoom: 11,
};

const mapStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
];

const Marker = ({ children }) => children;

const Map = () => {
  const { searchParams, isSearchSet } = useGetSearchParameters();

  // useRedirectToMobileApp(searchParams.place);

  // set side bar
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [currentData, setCurrentData] = useState(false);

  // loading data
  const [loading, setLoading] = useState(true);
  const [places, setPlaces] = useState([]);

  // map setup
  const mapRef = useRef();
  const [zoom, setZoom] = useState(initialValues.zoom);
  const [bounds, setBounds] = useState(null);

  //  load and format data
  const getPlaces = async () => {
    try {
      const { data } = await API.graphql(
        graphqlOperation(listPlaces, { limit: 300 })
      );
      const places = await data[Object.keys(data)[0]].items;

      setPlaces(places);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPlaces();
  }, []);

  const points = places.map((item) => ({
    type: "Feature",
    properties: {
      cluster: false,
      crimeId: item.id,
    },
    geometry: {
      type: "Point",
      coordinates: [
        JSON.parse(item.coordinates).lon,
        JSON.parse(item.coordinates).lat,
      ],
    },
    data: item,
  }));

  // get clusters
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: {
      radius: 175,
      maxZoom: 14,
    },
  });

  async function getCurrentPlaceByNumber(number) {
    try {
      const { data } = await API.graphql(
        graphqlOperation(getPlaceByNumber, { number: parseInt(number) })
      );

      const currentPlace = data[Object.keys(data)[0]].items[0];

      return currentPlace;
    } catch (err) {
      console.log(err);
    }
  }

  const getVictims = async (placeId, nextToken = null) => {
    try {
      const {
        data: {
          victimsByPlaceId: { items, nextToken: newToken },
        },
      } = await API.graphql(
        graphqlOperation(victimsByPlaceId, { placeId, nextToken })
      );

      if (newToken !== null) {
        return items.concat(await getVictims(placeId, newToken));
      } else {
        return items;
      }
    } catch (e) {
      throw new Error();
    }
  };

  function mapSetup(cords) {
    zoom < 15 && mapRef.current.setZoom(15);
    mapRef.current.panTo(cords);
  }

  function setPlaceUrl(key, value) {
    const params = `?${key}=${value}`;
    window.history.replaceState(null, null, params);
  }

  async function setNewPlace(number) {
    try {
      const place = await getCurrentPlaceByNumber(number);
      const victims = await getVictims(place.id);

      const coordinates = {
        lat: parseFloat(JSON.parse(place.coordinates).lat),
        lng: parseFloat(JSON.parse(place.coordinates).lon),
      };

      mapSetup(coordinates);
      setPlaceUrl("place", number);
      setCurrentData({ ...place, victims });
      setIsSideBarOpen(true);
    } catch (err) {
      console.log(err);
    }
  }

  // map handlers
  function handleMarkerClick(data) {
    const { number } = data;

    setNewPlace(number);
  }

  function setPlaceFromLinkQuery() {
    if (isSearchSet) {
      if (
        NativeAppLauncher.browserChecker().isAndroid ||
        NativeAppLauncher.browserChecker().isIOS
      ) {
        window.location = `tablicepamieci://places/${searchParams.place}`;
      }
      setNewPlace(searchParams.place);
    }
  }

  function resetMap() {
    mapRef.current.setZoom(initialValues.zoom);
    mapRef.current.panTo(initialValues.center);

    window.history.replaceState(null, null, "map");
    setCurrentData(false);
    setIsSideBarOpen(false);
  }

  useEffect(() => {
    const handleEscapePress = (e) => {
      if (e.keyCode === 27 && isSideBarOpen) {
        resetMap();
      }
    };

    document.addEventListener("keydown", handleEscapePress, false);
    return () => {
      document.removeEventListener("keydown", handleEscapePress);
    };
  }, [isSideBarOpen]);

  return (
    <MapTemplate>
      <NavBar />
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        defaultCenter={initialValues.center}
        defaultZoom={initialValues.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
          setPlaceFromLinkQuery();
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
        options={{
          styles: mapStyle,
          streetViewControl: false,
          scaleControl: false,
          mapTypeControl: false,
          panControl: false,
          zoomControl: false,
          rotateControl: false,
          fullscreenControl: false,
        }}
      >
        {loading ||
          clusters.map((cluster, i) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount,
            } = cluster.properties;

            if (isCluster) {
              return (
                <Marker key={`cluster-${i}`} lat={latitude} lng={longitude}>
                  <ClusterMarker
                    style={{
                      width: `${16 + (pointCount / points.length) * 40}px`,
                      height: `${16 + (pointCount / points.length) * 40}px`,
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(cluster.id),
                        20
                      );
                      mapRef.current.setZoom(expansionZoom);
                      mapRef.current.panTo({ lat: latitude, lng: longitude });
                    }}
                  >
                    {pointCount}
                  </ClusterMarker>
                </Marker>
              );
            }

            const isCurrentMarker = cluster.data.id === currentData.id;
            if (postOfficePlacesId.includes(cluster.data.id)) {
              return (
                <Marker key={`place-${i}`} lat={latitude} lng={longitude}>
                  <PointMarkerImage
                    onClick={() => handleMarkerClick(cluster.data)}
                    current={isCurrentMarker}
                  >
                    {isCurrentMarker ? (
                      <img src={activePostImage} alt="Post Office" width={45} />
                    ) : (
                      <img src={activePostImage} alt="Post Office" width={30} />
                    )}
                  </PointMarkerImage>
                </Marker>
              );
            } else {
              return (
                <Marker key={`place-${i}`} lat={latitude} lng={longitude}>
                  <PointMarker
                    onClick={() => handleMarkerClick(cluster.data)}
                    current={isCurrentMarker}
                  >
                    {isCurrentMarker && (
                      <IconBox>
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          color="#fff"
                          size="2x"
                        />
                      </IconBox>
                    )}
                  </PointMarker>
                </Marker>
              );
            }
          })}
      </GoogleMapReact>
      <ModalMapElement isOpen={isSideBarOpen} data={currentData} />
    </MapTemplate>
  );
};

const IconBox = styled.div`
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 2;
`;

const PointMarker = styled.div`
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: #6d7278;
  box-shadow: -2px 1px 4px 3px rgba(0, 0, 0, 0.12);
  cursor: pointer;

  ${({ current }) =>
    current &&
    css`
      width: 42px;
      height: 42px;
      cursor: unset;
      pointer-events: none;
    `}
`;

const PointMarkerImage = styled.div`
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: -2px 1px 4px 3px rgba(0, 0, 0, 0.12);
  cursor: pointer;

  ${({ current }) =>
    current &&
    css`
      width: 45px;
      height: 45px;
      cursor: unset;
      pointer-events: none;
    `}
`;

const ClusterMarker = styled.div`
  transform: translate(-50%, -50%);
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: rgb(147, 153, 160);
  min-width: 26px;
  min-height: 26px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  box-shadow: -2px 1px 4px 3px rgba(0, 0, 0, 0.12);
  cursor: pointer;
`;

export default Map;
