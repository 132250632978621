import React from "react";
import styled, { css } from "styled-components";

import Title from "../../atoms/Title/Title";

const ElementBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 320px;
  margin: 2.8vh 30px;
`;

const Image = styled.img`
  width: auto;
  height: 100px;
  margin-top: 12px;
`;

const PatronsList = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  ${({ isLast }) =>
    !isLast &&
    css`
      margin-bottom: 5vh;
    `};
`;

const StyledTitle = styled(Title)`
  text-align: center;
  color: ${({ theme }) => theme.font300};
  font-size: ${({ theme }) => theme.fontSize.m};
`;

const PatronsElement = ({ isLast = false, data = [], title }) => (
  <>
    <Title>{title}</Title>
    <PatronsList isLast={isLast}>
      {data.map((item) => (
        <ElementBox key={item.name}>
          <StyledTitle>{item.name}</StyledTitle>
          {item.logo && <Image src={item.logo} />}
        </ElementBox>
      ))}
    </PatronsList>
  </>
);

export default PatronsElement;
