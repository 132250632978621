import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { routes } from "../routes";

import LayoutTemplate from "../templates/LayoutTemplate";

import image_2 from "../images/home_2.png";
import image_2_en from "../images/home_2_en.png";
import image_2_de from "../images/logo_de.png";
import image_3 from "../images/home_3.png";
import image_4 from "../images/home_4.png";
import image_5 from "../images/home_5.png";

import HomeElement from "../components/molecules/HomeElement/HomeElement";
import Banner from "../components/molecules/Banner/Banner";
import { useRedirectToMobileApp } from "../hooks/useRedirectToMobileApp";
import { useGetSearchParameters } from "../hooks/useGetSearchParameters";

const multiLangLogo = {
  pl: image_2,
  en: image_2_en,
  de: image_2_de,
};

const displayElements = (data) => {
  return data.map((item, i) => (
    <HomeElement
      isHomePage={true}
      data={item}
      index={i}
      key={`${data.title}${i}`}
    />
  ));
};

const Home = () => {
  const { t, i18n } = useTranslation();

  const { searchParams } = useGetSearchParameters();
  console.log("Params: ", searchParams.place);
  useRedirectToMobileApp(searchParams.place);

  const pageData = useMemo(
    () => [
      {
        content: t("home_content_1"),
        image: "https://www.youtube.com/embed/hjkFqAx0al0",
        imageType: 1,
      },
      {
        title: t("project idea"),
        content: t("home_content_2"),
        link: routes.project,
        image: multiLangLogo[i18n.language],
        imageType: 0,
      },
      {
        title: t("map"),
        content: t("home_content_3"),
        link: routes.map,
        image: image_3,
        imageType: 0,
      },
      {
        title: t("education"),
        content: t("home_content_4"),
        link: routes.education,
        image: image_4,
        imageType: 0,
      },
      {
        title: t("multimedia"),
        content: t("home_content_5"),
        link: routes.media,
        image: image_5,
        imageType: 0,
      },
    ],
    [t, i18n.language]
  );

  return (
    <>
      <Banner />
      <LayoutTemplate home>{displayElements(pageData)}</LayoutTemplate>
    </>
  );
};

export default Home;
