import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { ThemeProvider } from "styled-components";

import GlobalStyle from "../theme/GlobalStyle";
import { theme } from "../theme/mainTheme";
import { SideModalProvider } from "../context/SideModalContext";

const MainTemplate = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <SideModalProvider>{children}</SideModalProvider>
    </ThemeProvider>
  );
};

MainTemplate.propTypes = {
  children: PropTypes.element.isRequired,
};

export default withRouter(MainTemplate);
