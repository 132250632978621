import { FETCH_SUCCESS, MENU_SUCCESS, GLOBAL_SUCCESS } from "../actions";

const initialState = {};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return {
        ...state,
        [action.payload.slug]: {
          ...state[action.payload.slug],
          [action.payload.lang]: action.payload,
        },
      };
    case MENU_SUCCESS:
      return {
        ...state,
        menu: {
          ...state.menu,
          [action.payload.lang]: action.payload,
        },
      };
    case GLOBAL_SUCCESS:
      return {
        ...state,
        global: action.payload,
      };
    default:
      return state;
  }
};

export default rootReducer;
