import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";

import Banner from "../components/molecules/Banner/Banner";
import PatronsElement from "../components/molecules/PatronsElement/PatronsElement";
import LayoutTemplate from "../templates/LayoutTemplate";

import bannerImage from "../images/banner_project.png";
import partner_logo from "../images/partner_logo_dark.jpg";
import patron_logo_1 from "../images/patron_logo_1_dark.png";
import patron_logo_2 from "../images/patron_logo_2.png";
import patron_logo_3 from "../images/patron_logo_3_dark.png";
import tpe_logo from "../images/tpe_logo.png";

const Patrons = () => {
  const { t } = useTranslation();

  const bannerInfo = useMemo(
    () => ({
      title: t("partners"),
      subtitle: t("partners_subtitle"),
      image: bannerImage,
    }),
    [t]
  );

  return (
    <>
      <Banner data={bannerInfo} />
      <LayoutTemplate>
        <PatronsElement
          title={`${t("partner_1")}`}
          data={[{ logo: partner_logo, name: "Instytut Pamięci Narodowej" }]}
        />
        <PatronsElement
          title={`${t("partner_2")}`}
          data={[
            {
              logo: patron_logo_1,
              name: "Ministerstwo Kultury i Dziedzictwa Narodowego i Sportu",
            },
            {
              logo: patron_logo_2,
              name: "Ministerstwo Obrony Narodowej",
            },
            {
              logo: patron_logo_3,
              name: "80. Rocznica Wybuchu II Wojny Światowej",
            },
          ]}
        />
        <PatronsElement
          isLast
          title={`${t("partner_3")}`}
          data={[
            {
              logo: tpe_logo,
              name: "Fundacja Towarzystwo Projektów Edukacyjnych",
            },
          ]}
        />
      </LayoutTemplate>
    </>
  );
};

export default Patrons;
