import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { NavLink, useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { routes } from "../../../routes";

import { LogoSvg } from "../../../images/logo";
import { LogoEnSvg } from "../../../images/logo_en";
import { LogoDeSvg } from "../../../images/logo_de";

import Logo from "../../atoms/Logo/Logo";
import SideNav from "../SideNav";
import LanguageSwitch from "../../molecules/LanguageSwitch/LanguageSwitch";

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [isTop, setIsTop] = useState(true);
  const [isSmallNav, setIsSmallNav] = useState(false);
  const [isSideOpen, setIsSideOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1140) setIsSmallNav(true);
      else setIsSmallNav(false);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (history.location.pathname === routes.map) setIsTop(false);
    else {
      document.addEventListener("scroll", () => {
        const position = window.scrollY;
        position <= 10 ? setIsTop(true) : setIsTop(false);
      });
    }
  }, [history]);

  const handleBurgerClick = () => {
    setIsSideOpen(!isSideOpen);
  };

  let logo = isTop ? <LogoSvg /> : <LogoSvg dark />;
  if (i18n.language === "en") logo = isTop ? <LogoEnSvg /> : <LogoEnSvg dark />;
  else if (i18n.language === "de")
    logo = isTop ? <LogoDeSvg /> : <LogoDeSvg dark />;

  if (isSmallNav) {
    return (
      <NavContainer isTop={isTop}>
        <NavInnerContainer>
          <NavLink exact to={routes.home}>
            <Logo isTop={isTop}>{logo}</Logo>
          </NavLink>
          <BurgerContainer
            isOpen={isSideOpen}
            isTop={isTop}
            onClick={handleBurgerClick}
          >
            <BurgerBar isTop={isTop} />
            <BurgerBar />
            <BurgerBar />
          </BurgerContainer>
        </NavInnerContainer>

        <SideNav isOpen={isSideOpen} handleClose={() => setIsSideOpen(false)} />
      </NavContainer>
    );
  } else {
    return (
      <NavContainer isTop={isTop}>
        <NavInnerContainer>
          <Logo to={routes.home} isTop={isTop}>
            <Link to={routes.home}>{logo}</Link>
          </Logo>
          <MenuItemsList>
            <StyledNavLink exact to={routes.home} activeClassName="active">
              <MenuItem route={routes.home} isTop={isTop}>
                {t("home page")}
              </MenuItem>
            </StyledNavLink>
            <StyledNavLink exact to={routes.map} activeClassName="active">
              <MenuItem route={routes.map} isTop={isTop}>
                {t("map")}
              </MenuItem>
            </StyledNavLink>
            <StyledNavLink exact to={routes.victims} activeClassName="active">
              <MenuItem route={routes.victims} isTop={isTop}>
                {t("VictimsNavHeader")}
              </MenuItem>
            </StyledNavLink>
            <StyledNavLink as="a" href="https://www.wystawa.tablicepamieci.pl/">
              <MenuItem isTop={isTop}>{t("online exhibition")}</MenuItem>
            </StyledNavLink>
            <StyledNavLink exact to={routes.project} activeClassName="active">
              <MenuItem route={routes.project} isTop={isTop}>
                {t("project idea")}
              </MenuItem>
            </StyledNavLink>
            <StyledNavLink exact to={routes.education} activeClassName="active">
              <MenuItem route={routes.education} isTop={isTop}>
                {t("education")}
              </MenuItem>
            </StyledNavLink>
            <StyledNavLink exact to={routes.media} activeClassName="active">
              <MenuItem route={routes.media} isTop={isTop}>
                {t("multimedia")}
              </MenuItem>
            </StyledNavLink>
            <StyledNavLink exact to={routes.mobile} activeClassName="active">
              <MenuItem route={routes.mobile} isTop={isTop}>
                {t("mobile app")}
              </MenuItem>
            </StyledNavLink>
            <StyledNavLink exact to={routes.partners} activeClassName="active">
              <MenuItem route={routes.partners} isTop={isTop}>
                {t("partners")}
              </MenuItem>
            </StyledNavLink>
          </MenuItemsList>
          <LanguageSwitch />
        </NavInnerContainer>
      </NavContainer>
    );
  }
};

const NavContainer = styled.div`
  width: 100%;
  background-color: ${({ isTop }) => (isTop ? "transparent" : "#ffffff")};
  position: fixed;
  top: 0;
  left: 0;
  /* padding: ${({ isTop }) => (isTop ? "20px 0" : "0")}; */
  padding: 14px 0;
  box-shadow: ${({ isTop }) => (isTop ? "none" : " 0px -15px 20px 5px black")};
  z-index: 90;
  transition: background 0.2s ease-in-out, padding 0.35s ease-in-out;

  @media (max-width: 1140px) {
    padding: 4px 0;
  }
`;

const NavInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 94%;
  max-width: 1600px;
  height: 66px;
  margin: 0 auto;

  @media (max-width: 1140px) {
    height: 50px;
  }
`;

const MenuItemsList = styled.ul`
  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const MenuItem = styled.li`
  margin: 8px 18px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: ${({ theme, isTop }) => (isTop ? theme.font100 : theme.font400)};
  transition: color 0.2s ease-in-out;
  text-transform: uppercase;
  color: ${({ isTop }) => (isTop ? "none" : " 0px -15px 20px 5px black")};
  font-family: "Poppins", sans-serif;

  @media (max-width: 1260px) {
    margin: 8px;
  }

  &:hover {
    color: ${({ theme }) => theme.red200};
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;

  &.active ${MenuItem} {
    color: ${({ theme }) => theme.red200};
  }
`;

const BurgerBar = styled.span`
  transition: all 0.2s;
  background-color: #fff;
  width: 28px;
  height: 2px;
  border-radius: 3px;

  :nth-of-type(2) {
    width: 22px;
  }
`;

const BurgerContainer = styled.div`
  width: 44px;
  height: 21px;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;

  ${BurgerBar} {
    background-color: ${({ isTop }) => (isTop ? "#fff" : "#000")};
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      position: relative;

      ${BurgerBar} {
        position: absolute;

        :nth-of-type(1) {
          transform: rotate(45deg);
          top: 21px;
        }

        :nth-of-type(2) {
          opacity: 0;
        }

        :nth-of-type(3) {
          transform: rotate(-45deg);
          top: 21px;
        }
      }
    `}
`;

export default NavBar;
