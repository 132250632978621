import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

    *, *::before, *::after { 
        box-sizing: border-box;
        /* -webkit-font-smoothing: antialiased; */
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
    }

    html {
        font-size: 62.5%;
    }

    body {
        font-size: 1.6rem;
        font-family: 'Roboto', sans-serif;

        /* SECOND FONT */
        /* font-family: 'Poppins', sans-serif; */
    }

    p {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }

    iframe {
        border: none;
    }
`;

export default GlobalStyle;
