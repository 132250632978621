export const theme = {
  font100: "#ffffff",
  font200: "#a2a9bf",
  font300: "#4a4a4a",
  font400: "#131313",
  red100: "#ff4157",
  red200: "#ff1d37",
  grey100: "hsl(0, 0%, 96%)",
  grey200: "hsl(0, 0%, 90%)",
  grey300: "hsl(0, 0%, 70%)",
  black300: "#0a1015",
  black: "hsl(0, 0%, 0%)",
  blue: "#2979ff",
  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 900,
  },
  fontSize: {
    xxs: "1.2rem",
    xs: "1.4rem",
    s: "1.6rem",
    m: "1.8rem",
    l: "2rem",
    xl: "2.4rem",
    xxl: "3rem",
    xxxl: "4.8rem",
  },
  poppins: '"Poppins", sans-serif',
  roboto: '"Roboto", sans-serif',
};
