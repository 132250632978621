import { useState } from "react";

const useModal = () => {
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [options, setOptions] = useState({});

  const defConf = {
    left: false,
    map: false,
    width: 420,
    background: "rgba(52, 51, 91, 0.14)",
  };

  const handleModal = (content = false, conf = {}) => {
    if (content) setModalContent(content);
    if (!modal) setOptions({ ...defConf, ...conf });
    setModal(content ? !modal : false);
  };
  return { modal, handleModal, modalContent, options };
};

export default useModal;
