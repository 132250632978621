import React from "react";
import ReactDOM from "react-dom";
import Root from "./views/Root";
import SimpleReactLightbox from "simple-react-lightbox";
import Amplify from "aws-amplify";

import "./i18n";
import awsExports from "./aws-exports";

Amplify.configure(awsExports);

ReactDOM.render(
  <SimpleReactLightbox>
    <Root />
  </SimpleReactLightbox>,
  document.getElementById("root")
);
