import React, { useState } from "react";
import styled from "styled-components";
import FsLightbox from "fslightbox-react";

const matchYoutubeUrl = (url) => {
  var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  return url.match(p) ? true : false;
};

const TitleBox = styled.div`
  opacity: 0;
  transform: translateY(30px);
  width: 90%;
  height: 90%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.red100};
  font-size: 18px;
  line-height: 1.333;
  font-weight: 700;
  padding: 12px;
  pointer-events: none;
  user-select: none;
  z-index: 3;
  transition: 0.2s ease-in-out;
`;

const TileBox = styled.a`
  width: 100%;
  height: 260px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-image: url(${({ background }) => background});
  cursor: pointer;

  &:hover ${TitleBox} {
    opacity: 1;
    transform: translateX(0);
  }
`;

const ImageTile = ({ title, image, link, redirect }) => {
  const [isOpen, setIsOpen] = useState(false);

  if (redirect) {
    return (
      <TileBox
        href={link}
        background={image}
        target="_blank"
        rel="noopener noreferrer"
      >
        <TitleBox>{title}</TitleBox>
      </TileBox>
    );
  } else {
    const videoType = matchYoutubeUrl(link) ? "youtube" : "video";
    return (
      <>
        <TileBox as="div" background={image} onClick={() => setIsOpen(true)}>
          <TitleBox>{title}</TitleBox>
        </TileBox>
        <FsLightbox toggler={isOpen} sources={[link]} type={videoType} />
      </>
    );
  }
};

export default ImageTile;
