import styled from "styled-components";

const AboveTitle = styled.h5`
  position: relative;
  color: ${({ theme }) => theme.font300};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-family: ${({ theme }) => theme.roboto};
  margin-bottom: 16px;
  letter-spacing: 1.4px;
  line-height: 0.8;
  text-transform: uppercase;
  padding: 0 35px;
  width: fit-content;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-48%);
    width: 20px;
    height: 2px;
    background-color: ${({ theme }) => theme.red100};
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
    display: none;

    /* @media (max-width: 880px) {
      display: block;
    } */
  }
`;

export default AboveTitle;
