import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { API, graphqlOperation } from "aws-amplify";
import Skeleton from "react-loading-skeleton";

import Banner from "../components/molecules/Banner/Banner";
import Title from "../components/atoms/Title/Title";
import Lead from "../components/atoms/Lead/Lead";
import SideImage from "../components/atoms/SideImage/SideImage";
import AboveTitle from "../components/atoms/AboveTitle/AboveTitle";
import ImageTile from "../components/molecules/ImageTile";
import MainButton from "../components/atoms/MainButton/MainButton";

import LayoutTemplate from "../templates/LayoutTemplate";
import GridTemplate from "../templates/GridTemplate";
import HorizontalElementTemplate from "../templates/HorizontalElementTemplate";

import mediaImage from "../images/media.png";
import bannerImage from "../images/banner_media.png";

const listMedia = /* GraphQL */ `
  query ListMedia($limit: Int = 6, $nextToken: String) {
    listMultimediasByType(
      limit: $limit
      nextToken: $nextToken
      sortDirection: DESC
      type: DEFAULT
    ) {
      items {
        id
        photo
        title
        url
        redirect
        createdAt
      }
      nextToken
    }
  }
`;

const ContentBox = styled.div`
  width: 45%;
  padding: 20px 0 6vh 4%;
  align-self: flex-start;

  @media (max-width: 992px) {
    width: 100%;
    padding: 20px 0 2vh 0;
  }
`;

const BottomBox = styled.div`
  margin-top: 60px;
  width: 100%;

  @media (max-width: 992px) {
    margin-top: 14px;
  }
`;

const Media = () => {
  const { t } = useTranslation();

  const bannerInfo = {
    title: t("multimedia"),
    subtitle: t("multimedia_subtitle"),
    image: bannerImage,
  };

  const [media, setMedia] = useState([]);
  const [isMediaLoading, setIsMediaLoading] = useState(false);

  const [nextToken, setNextToken] = useState();

  const getMedia = async () => {
    nextToken || setIsMediaLoading(true);
    try {
      const { data } = await API.graphql(
        graphqlOperation(listMedia, { nextToken })
      );

      const newToken = data[Object.keys(data)[0]].nextToken;
      setNextToken(newToken);

      const fetchedMedia = data[Object.keys(data)[0]].items;
      setMedia([...media, ...fetchedMedia]);
    } catch (err) {
      console.log(err);
    } finally {
      setIsMediaLoading(false);
    }
  };

  useEffect(() => {
    getMedia();
    // eslint-disable-next-line
  }, []);

  const displayMedia = () => {
    if (isMediaLoading) {
      return (
        <>
          <Skeleton height={260} />
          <Skeleton height={260} />
          <Skeleton height={260} />
          <Skeleton height={260} />
          <Skeleton height={260} />
          <Skeleton height={260} />
        </>
      );
    } else if (media) {
      return media.map(({ id, url, photo, title, redirect }) => {
        return (
          <ImageTile
            key={id}
            title={title}
            image={photo}
            link={url}
            redirect={redirect}
          />
        );
      });
    }
  };

  return (
    <>
      <Banner data={bannerInfo} />
      <LayoutTemplate>
        <HorizontalElementTemplate>
          <SideImage src={mediaImage} />
          <ContentBox>
            <AboveTitle>{t("multimedia")}</AboveTitle>
            <Title big>{t("media_title")}</Title>
            <Lead big>{t("media_lead")}</Lead>
          </ContentBox>
        </HorizontalElementTemplate>
        <BottomBox>
          <GridTemplate>{displayMedia()}</GridTemplate>
          {nextToken && (
            <StyledMainButton onClick={getMedia}>{t("more")}</StyledMainButton>
          )}
        </BottomBox>
      </LayoutTemplate>
    </>
  );
};

const StyledMainButton = styled(MainButton)`
  margin: 40px auto 0;
`;

export default Media;
