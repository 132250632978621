import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import styled, { css } from "styled-components";
import { gsap, Power2 } from "gsap";

import i18n from "../../../i18n";
import { useDetectOutsideClick } from "../../../hooks/useDetectOutsideClick";

import pl_flag from "../../../images/pl_flag.png";
import en_flag from "../../../images/en_flag.png";
import de_flag from "../../../images/de_flag.png";

const langs = [
  {
    name: "polski",
    lang: "pl",
    icon: pl_flag,
  },
  {
    name: "english",
    lang: "en",
    icon: en_flag,
  },
  {
    name: "deutsche",
    lang: "de",
    icon: de_flag,
  },
];

const LangContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

const IconBox = styled.div`
  display: flex;
  width: 23px;
`;

const SwitchContainer = styled.div`
  position: relative;
  width: fit-content;
  width: ${({ isAlt }) => isAlt && "100%"};
`;

const OptonsContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(calc(100% + 10px));
  width: 130px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: -4px 2px 7px 3px #0000000d;
  overflow: hidden;
  padding: 8px;

  ${({ isAlt }) =>
    isAlt &&
    css`
      box-shadow: none;
      padding: 0;
      width: 100%;
    `}
`;

const LangElement = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* transition: 0.24s ease-in-out; */

  :not(:last-of-type) {
    margin-bottom: 10px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const LangName = styled.p`
  padding-right: 20px;
`;

const Image = styled.img`
  width: 23px;
  height: 23px;
`;

const LangSwitch = ({ alt }) => {
  const [isLangOpen, setIsLangOpen] = useState(false);
  const [currLang, setCurrLang] = useState();

  const langBox = useRef(null);
  const switchBox = useRef(null);

  useEffect(() => {
    const [currentLang] = langs.filter((item) => item.lang === i18n.language);
    setCurrLang(currentLang);
  }, [setCurrLang]);

  useEffect(() => {
    const tl = gsap.timeline();
    tl.to(langBox.current, {
      autoAlpha: isLangOpen ? 1 : 0,
      duration: 0.16,
      ease: Power2.easeInOut,
    }).fromTo(
      langBox.current.children,
      { y: -20, autoAlpha: 0 },
      { y: 0, autoAlpha: 1, duration: 0.2, stagger: 0.08 }
    );
  }, [langBox, isLangOpen]);

  useLayoutEffect(() => {
    gsap.set(langBox.current, {
      autoAlpha: 0,
    });
  }, []);

  const handlerLanguageChange = (data) => {
    const { lang } = data;
    i18n.changeLanguage(lang);
    setCurrLang(data);
    setIsLangOpen((prevState) => !prevState);
  };

  useDetectOutsideClick(switchBox, setIsLangOpen);

  return (
    <SwitchContainer ref={switchBox} isAlt={alt}>
      <LangContainer onClick={() => setIsLangOpen((prevState) => !prevState)}>
        <IconBox>{currLang && <Image src={currLang.icon} />}</IconBox>
      </LangContainer>
      <OptonsContainer ref={langBox} isAlt={alt}>
        {currLang &&
          langs.map(
            (item) =>
              item.name !== currLang.name && (
                <LangElement
                  key={item.name}
                  onClick={() => handlerLanguageChange(item)}
                >
                  <LangName>{item.name}</LangName>
                  <IconBox>
                    <Image src={item.icon} />
                  </IconBox>
                </LangElement>
              )
          )}
      </OptonsContainer>
    </SwitchContainer>
  );
};

export default LangSwitch;
