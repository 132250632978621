import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";
import { useTranslation } from "react-i18next";

import Title from "../../atoms/Title/Title";
import Paragraph from "../../atoms/Paragraph/Paragraph";
import MainButton from "../../atoms/MainButton/MainButton";
import HorizontalElementTemplate from "../../../templates/HorizontalElementTemplate";
import { AspectRatioElement } from "../../atoms/AspectRatioContainer";

const ContentBox = styled.div`
  width: 50%;
  padding: ${({ index }) =>
    index % 2 === 0 ? "6vh 0 6vh 8%" : "6vh 8% 6vh 0"};

  @media (max-width: 768px) {
    width: 100%;
    padding: 6vh 0 6vh 0;
  }
`;

const MediaBox = styled.div`
  width: 50%;

  @media (max-width: 768px) {
    margin: 0 auto;
    width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const IframeElement = styled(Iframe)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const HomeElement = ({ data, index, isHomePage }) => {
  const { t } = useTranslation();
  const { title, link, content, image, imageType } = data;

  const displayMedia = () => {
    if (imageType === 0) {
      return <Image src={image} />;
    } else {
      return (
        <AspectRatioElement>
          <IframeElement src={image} />
        </AspectRatioElement>
      );
    }
  };

  return (
    <HorizontalElementTemplate index={index} isHomePage={isHomePage}>
      <MediaBox>{displayMedia()}</MediaBox>
      <ContentBox index={index}>
        {title && <Title>{title}</Title>}
        <Paragraph>{content}</Paragraph>
        {link && (
          <MainButton as={Link} to={link}>
            {t("more")}
          </MainButton>
        )}
      </ContentBox>
    </HorizontalElementTemplate>
  );
};

export default HomeElement;
