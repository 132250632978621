import React, { useEffect } from "react";

import NativeAppLauncher from "../components/DeepLink";
import { appstore_url, googleplay_url } from "../variables";

const MobileAppRedirect = () => {
  useEffect(() => {
    if (NativeAppLauncher.browserChecker().isAndroid) {
      // alert("Android");
      window.location = googleplay_url;
    } else if (NativeAppLauncher.browserChecker().isIOS) {
      // alert("IOS");
      setTimeout(function () {
        window.location = appstore_url;
      }, 25);
      window.location = "tablicepamieci://place/20";
    } else {
      // alert("Desktop");
      window.location = window.location.origin;
    }
  }, []);

  return (
    <>
      <button type="button" style={{ display: "none" }} id="open-app-link" />
    </>
  );
};

export default MobileAppRedirect;
