import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { SRLWrapper } from "simple-react-lightbox";
import Iframe from "react-iframe";

import LayoutTemplate from "../templates/LayoutTemplate";
import Banner from "../components/molecules/Banner/Banner";
import AboveTitle from "../components/atoms/AboveTitle/AboveTitle";
import Title from "../components/atoms/Title/Title";
import { AspectRatioElement } from "../components/atoms/AspectRatioContainer";

import bannerImage from "../images/banner_education.png";

import education_article_1_1 from "../images/education_article_1.1.png";
import education_article_1_2 from "../images/education_article_1.2.png";
import education_article_1_3 from "../images/education_article_1.3.png";
import education_article_1_4 from "../images/education_article_1.4.png";

import education_article_2_1 from "../images/education_article_2.1.png";
import education_article_2_2 from "../images/education_article_2.2.png";
import education_article_2_3 from "../images/education_article_2.3.png";
import education_article_2_4 from "../images/education_article_2.4.png";

import education_article_3_1 from "../images/education_article_3.1.png";
import education_article_3_2 from "../images/education_article_3.2.png";
import education_article_3_3 from "../images/education_article_3.3.png";
import education_article_3_4 from "../images/education_article_3.4.png";

const articlesMedia = [
  {
    gallery: [
      {
        title: "",
        url: education_article_1_1,
      },
      {
        title: "",
        url: education_article_1_2,
      },
      {
        title: "",
        url: education_article_1_3,
      },
      {
        title: "",
        url: education_article_1_4,
      },
    ],
    videoUrl: false,
  },
  {
    gallery: [
      {
        title: "",
        url: education_article_2_1,
      },
      {
        title: "",
        url: education_article_2_2,
      },
      {
        title: "",
        url: education_article_2_3,
      },
      {
        title: "",
        url: education_article_2_4,
      },
    ],
    videoUrl: "https://www.youtube.com/embed/r2zZrKZUw8Y",
  },
  {
    gallery: [
      {
        title: "",
        url: education_article_3_1,
      },
      {
        title: "",
        url: education_article_3_2,
      },
      {
        title: "",
        url: education_article_3_3,
      },
      {
        title: "",
        url: education_article_3_4,
      },
    ],
    videoUrl: false,
  },
];

const Education = () => {
  const { t } = useTranslation();

  const bannerInfo = {
    title: t("education"),
    subtitle: t("education_subtitle"),
    image: bannerImage,
  };

  const displayElements = () => {
    const educationArticles = t("education_articles").map((item, i) => {
      const { gallery, videoUrl } = articlesMedia[i];

      return (
        <EducationArticle key={`${item.title}_${i}`}>
          <AboveTitle>{t("education")}</AboveTitle>
          <Title big>{item.title}</Title>
          <Content>{parse(item.content)}</Content>
          {videoUrl && (
            <RatioContainer>
              <IframeElement src={videoUrl} />
            </RatioContainer>
          )}
          {gallery.length > 0 && (
            <SRLWrapper>
              <GalleryContainer>
                {gallery.map((item, i) => (
                  <ImageLink key={`${item.title}_${i}`} href={item.url}>
                    <ImageIcon src={item.url} alt={item.title} />
                  </ImageLink>
                ))}
              </GalleryContainer>
            </SRLWrapper>
          )}
        </EducationArticle>
      );
    });

    return educationArticles;
  };

  return (
    <>
      <Banner data={bannerInfo} />
      <LayoutTemplate>{displayElements()}</LayoutTemplate>
    </>
  );
};

const RatioContainer = styled(AspectRatioElement)`
  margin-top: 30px;
`;

const IframeElement = styled(Iframe)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const GalleryContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 20px;
  margin-top: 30px;
`;

const ImageIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const ImageLink = styled.a`
  display: block;
  width: 100%;
`;

const EducationArticle = styled.div`
  width: 100%;
  margin-bottom: 42px;
`;

const Content = styled.div`
  width: 100%;
  margin-top: 22px;

  p {
    margin: 8px 0;
    font-size: 16px;
    line-height: 1.6;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    color: #4b4b4b;
  }

  h3 {
    margin: 10px 0;
  }

  h4 {
    margin: 5px 0 10px;
  }
`;

export default Education;
