import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";

import Title from "../../atoms/Title/Title";
import VideoBackground from "../../atoms/VideoBackground/VideoBackground";
import ScrollTrigger from "../../atoms/ScrollTrigger/ScrollTrigger";

const BannerMain = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;

  ${({ isPage }) =>
    isPage &&
    css`
      height: 55vh;

      @media (max-width: 1200px) {
        height: 280px;
      }
    `};
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

const VideoBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 1;
  z-index: 2;
`;

const PageH1 = styled(Title)`
  color: ${({ theme }) => theme.font100};
  font-size: 6rem;
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1200px) {
    font-size: 2.75rem;
  }
`;

const Subtitle = styled.h4`
  color: ${({ theme }) => theme.font100};
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  @media (max-width: 1200px) {
    font-size: 1.4rem;
  }
`;

const PageBox = styled.div`
  width: 94%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  z-index: 3;
`;

const H1 = styled.h1`
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 48px;
  z-index: 3;
  max-width: 1200px;
  text-align: center;
  line-height: normal;

  @media (max-width: 1200px) {
    font-size: 2.4rem;
    max-width: 88vw;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;

const videoLink =
  "https://tablicepamieci.s3.eu-central-1.amazonaws.com/multimedia/TabliceMovie.mp4";

const Banner = ({ data = {} }) => {
  const { t } = useTranslation();

  const { title, subtitle, image } = data;
  const [isPage, setIsPage] = useState(false);

  useEffect(() => {
    if (title) setIsPage(true);
  }, [setIsPage, title]);

  return (
    <BannerMain isPage={isPage}>
      {isPage ? (
        <VideoBox>
          <PageBox>
            <PageH1>{title}</PageH1>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </PageBox>
          <BackgroundImage src={image} />
          <Overlay />
        </VideoBox>
      ) : (
        <>
          <VideoBox>
            <H1>"{t("home_banner")}."</H1>
            <VideoBackground src={videoLink} />
            <Overlay />
          </VideoBox>
          <Content>
            <ScrollTrigger />
          </Content>
        </>
      )}
    </BannerMain>
  );
};

export default Banner;
