import React from "react";
import styled from "styled-components";

const Video = styled.video`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;
const Source = styled.source``;

const VideoBackground = ({ src }) => (
  <Video autoPlay loop muted playsinline>
    <Source src={src} type="video/mp4" playsinline />
  </Video>
);

export default VideoBackground;
