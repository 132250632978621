import React, { createContext } from "react";

import useModal from "../hooks/useModal";
import SideModal from "../components/modal/SideModal";

let SideModalContext;
const { Provider } = (SideModalContext = createContext());

const SideModalProvider = ({ children }) => {
  const {
    modal: sideModal,
    handleModal: handleSideModal,
    modalContent: sideModalContent,
    options,
  } = useModal();

  return (
    <Provider value={{ sideModal, handleSideModal, sideModalContent }}>
      <SideModal {...options} />
      {children}
    </Provider>
  );
};

export { SideModalContext, SideModalProvider };
