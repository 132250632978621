import React from "react";
import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import LanguageSwitch from "../molecules/LanguageSwitch/LanguageSwitch";

import { routes } from "../../routes";

const SideNav = ({ isOpen, handleClose }) => {
  const { t } = useTranslation();

  return (
    <SideBox isOpen={isOpen}>
      <SideContainer isOpen={isOpen}>
        <NavElement exact to={routes.home} activeClassName="active">
          {t("home page")}
        </NavElement>
        <NavElement exact to={routes.map} activeClassName="active">
          {t("map")}
        </NavElement>
        <NavElement exact to={routes.victims} activeClassName="active">
          {t("VictimsNavHeader")}
        </NavElement>
        <NavElement as="a" href="https://www.wystawa.tablicepamieci.pl/">
          {t("online exhibition")}
        </NavElement>
        <NavElement exact to={routes.project} activeClassName="active">
          {t("project idea")}
        </NavElement>
        <NavElement exact to={routes.education} activeClassName="active">
          {t("education")}
        </NavElement>
        <NavElement exact to={routes.media} activeClassName="active">
          {t("multimedia")}
        </NavElement>
        <NavElement exact to={routes.mobile} activeClassName="active">
          {t("mobile app")}
        </NavElement>
        <NavElement exact to={routes.partners} activeClassName="active">
          {t("partners")}
        </NavElement>
        <NavElement as="div" border={false}>
          <LanguageSwitch alt />
        </NavElement>
      </SideContainer>
      <Background isOpen={isOpen} onClick={handleClose} />
    </SideBox>
  );
};

const SideBox = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      &,
      * {
        user-select: none;
        pointer-events: none;
      }
    `}
`;

const SideContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  transform: translate(${({ isOpen }) => (isOpen ? 0 : -120)}%);
  padding: 60px 25px 0;
  width: 300px;
  background-color: #fff;
  height: 100%;
  transition: 0.4s ease-in-out;
  overflow-y: auto;
  z-index: 2;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.64);
  width: 100%;
  height: 100%;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: 0.4s ease-in-out;
  z-index: 1;
`;

const NavElement = styled(NavLink)`
  display: block;
  padding: 12px 0;
  text-transform: capitalize;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: 16px;
  text-decoration: none;
  color: #000;
  font-family: "Poppins", sans-serif;

  ${({ border = true }) =>
    border &&
    css`
      border-bottom: 1px solid rgba(219, 228, 247, 0.75);
    `}

  &.active {
    color: ${({ theme }) => theme.red200};
  }
`;

export default SideNav;
