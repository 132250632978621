import React from "react";
import styled from "styled-components";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 30px;
  width: 100%;
  margin-top: ${({ top }) => `${top}vh`};
`;

const GridTemplate = ({ children, top = 0 }) => (
  <Grid top={top}>{children}</Grid>
);

export default GridTemplate;
