import styled from "styled-components";

const defaultRatio = "16 / 9";

function stringToNumberArray(stringArray) {
  return stringArray.map((item) => parseInt(item.trim()));
}

function prepareAspectRatio(ratio) {
  const stringArray = ratio.split("/");
  const ratioArray = stringToNumberArray(stringArray);
  return (ratioArray[1] / ratioArray[0]) * 100;
}

export const AspectRatioElement = styled.div`
  position: relative;
  height: 0;
  width: 100%;
  padding-top: ${({ aspectRatio }) =>
    aspectRatio
      ? `${prepareAspectRatio(aspectRatio)}%`
      : `${prepareAspectRatio(defaultRatio)}%`};
`;
