import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

import store from "../store";
import { routes } from "../routes";

import "../App.css";
import MainTemplate from "../templates/MainTemplate";
import Home from "./Home";
import Map from "./Map";
import Victims from "./Victims";
import Project from "./Project";
import Media from "./Media";
import Education from "./Education";
import Mobile from "./Mobile";
import Patrons from "./Patrons";
import MobileAppRedirect from "./MobileAppRedirect";

function Root() {
  const { t } = useTranslation();

  return (
    <BrowserRouter>
      <Provider store={store}>
        <MainTemplate>
          <>
            <Switch>
              <Route exact path={routes.home} component={Home} />
              <Route exact path={routes.map} component={Map} />
              <Route exact path={routes.victims} component={Victims} />
              <Route exact path={routes.project} component={Project} />
              <Route exact path={routes.education} component={Education} />
              <Route exact path={routes.media} component={Media} />
              <Route exact path={routes.mobile} component={Mobile} />
              <Route exact path={routes.partners} component={Patrons} />
              <Route
                exact
                path={routes.mobileAppRedirect}
                component={MobileAppRedirect}
              />
            </Switch>
            <CookieConsent
              location="bottom"
              cookieName="cookiesPolicy"
              style={{ background: "#fff", fontSize: "16px", color: "#000" }}
              buttonText={t("I understand")}
              buttonStyle={{
                padding: "12px 16px",
                background: "#ff4157",
                color: "#fff",
                borderRadius: "2px",
                fontSize: "14px",
                fontWeight: 700,
                minWidth: "120px",
                textAlign: "center",
                textTransform: "uppercase",
                fontFamily: "'Poppins', sans-serif",
              }}
              expires={150}
            >
              Wykorzystujemy pliki cookie w celu poprawy komfortu korzystania z
              naszej strony.
            </CookieConsent>
          </>
        </MainTemplate>
      </Provider>
    </BrowserRouter>
  );
}

export default Root;
