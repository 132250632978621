import { ReactComponent as Pl } from "./assets/pl.svg";
import { ReactComponent as En } from "./assets/en.svg";
import { ReactComponent as De } from "./assets/de.svg";

export const BASE_URL = "http://localhost";
export const LANGUAGES = [
  {
    name: "polski",
    slug: "pl",
    icon: Pl,
  },
  {
    name: "english",
    slug: "en",
    icon: En,
  },
  {
    name: "deutsch",
    slug: "de",
    icon: De,
  },
];

export const SOCIAL_MEDIA = {
  facebook: "https://www.facebook.com/gkpge",
  youtube: "https://www.youtube.com/gkpge",
  instagram: "https://www.instagram.com/grupa_pge/",
  linkedin: "https://www.linkedin.com/company/pge-s.a./",
  twitter: "https://twitter.com/Grupa_PGE",
};
