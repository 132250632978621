import styled from "styled-components";

const Logo = styled.div`
  width: ${({ isTop }) => (isTop ? "80px" : "64px")};

  height: auto;
  margin: 6px ${({ isTop }) => (isTop ? "0" : "16px")} 6px 0;
  transition: 0.18s ease-in-out;

  @media (max-width: 1260px) {
    width: 42px;
  }
`;

export default Logo;
