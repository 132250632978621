import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Banner from "../components/molecules/Banner/Banner";
import Title from "../components/atoms/Title/Title";
import Lead from "../components/atoms/Lead/Lead";
import AboveTitle from "../components/atoms/AboveTitle/AboveTitle";

import LayoutTemplate from "../templates/LayoutTemplate";
import HorizontalElementTemplate from "../templates/HorizontalElementTemplate";

import bannerImage from "../images/banner_project.png";
import appstore from "../images/appstore.png";
import googleplay from "../images/googleplay.png";
import appstore_ang from "../images/appstore_ang.png";
import googleplay_ang from "../images/googleplay_ang.png";

import { appstore_url, googleplay_url } from "../variables";
import { Qrcode } from "../images/Qrcode";
import i18n from "../i18n";

const ContentBox = styled.div`
  width: 60%;
  padding: 20px 0 6vh 4%;
  align-self: flex-start;

  @media (max-width: 992px) {
    width: 100%;
    padding: 20px 0 2vh 0;
  }
`;
const Buttons = styled.div`
  display: flex;
  margin-top: 24px;
`;
const LinkTo = styled.a`
  margin-right: 12px;
  width: 160px;
`;
const StyledLinkTo = styled(LinkTo)`
  width: calc(160px - 2 * 15px);
`;
const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;
const SVGContainer = styled.div`
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
`;

const Mobile = () => {
  const { t } = useTranslation();
  const currentLang = i18n.language;

  const bannerInfo = {
    title: t("mobile app"),
    subtitle: t("mobile_app_subtitle"),
    image: bannerImage,
  };

  return (
    <>
      <Banner data={bannerInfo} />
      <LayoutTemplate>
        <HorizontalElementTemplate>
          <SVGContainer>
            <Qrcode />
          </SVGContainer>
          <ContentBox>
            <AboveTitle>{t("mobile app")}</AboveTitle>
            <Title big>{t("mobile_title")}</Title>
            <Lead big>{t("mobile_lead")}</Lead>
            {currentLang === "pl" ? (
              <Buttons>
                <StyledLinkTo
                  href={appstore_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={appstore} alt="App Store logo" />
                </StyledLinkTo>
                <LinkTo
                  href={googleplay_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={googleplay} alt="Google Play logo" />
                </LinkTo>
              </Buttons>
            ) : (
              <Buttons>
                <LinkTo
                  href={appstore_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={appstore_ang} alt="App Store logo" />
                </LinkTo>
                <LinkTo
                  href={googleplay_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={googleplay_ang} alt="Google Play logo" />
                </LinkTo>
              </Buttons>
            )}
          </ContentBox>
        </HorizontalElementTemplate>
      </LayoutTemplate>
    </>
  );
};

export default Mobile;
