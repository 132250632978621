import styled from "styled-components";

const Lead = styled.p`
  color: ${({ theme }) => theme.font300};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-family: ${({ theme }) => theme.roboto};
  line-height: 3.6rem;
  margin-top: 13px;

  @media (max-width: 992px) {
    font-size: ${({ theme }) => theme.fontSize.l};
  }
`;

export default Lead;
