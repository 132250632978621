import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import renderHTML from "react-render-html";

import Title from "../components/atoms/Title/Title";
import Lead from "../components/atoms/Lead/Lead";
import SideImage from "../components/atoms/SideImage/SideImage";
import Paragraph from "../components/atoms/Paragraph/Paragraph";
import Content from "../components/atoms/Content/Content";
import AboveTitle from "../components/atoms/AboveTitle/AboveTitle";

import Banner from "../components/molecules/Banner/Banner";
import bigLogo from "../images/big_logo.png";
import image_2_en from "../images/home_2_en.png";
import image_2_de from "../images/logo_de.png";

import LayoutTemplate from "../templates/LayoutTemplate";
import GridTemplate from "../templates/GridTemplate";
import HorizontalElementTemplate from "../templates/HorizontalElementTemplate";

import bannerImage from "../images/banner_project.png";

const multiLangLogo = {
  pl: bigLogo,
  en: image_2_en,
  de: image_2_de,
};

const ContentBox = styled.div`
  width: 50%;
  padding: 20px 0 6vh 4%;
  align-self: flex-start;

  @media (max-width: 992px) {
    width: 100%;
    padding: 20px 0 2vh 0;
  }
`;

const StyledTitle = styled(Title)`
  align-self: flex-start;
  margin-top: 4vh;
  margin-bottom: 2.4vh;
`;

const Project = () => {
  const { t, i18n } = useTranslation();

  const bannerInfo = {
    title: t("project idea"),
    subtitle: t("project_idea_subtitle"),
    image: bannerImage,
  };

  const displayProjects = () => {
    const tileElements = [];
    for (let i = 1; i <= 6; i++) {
      const translation = t(`project_tile_${i}`);

      tileElements.push(<Paragraph key={i}>{translation}</Paragraph>);
    }

    return tileElements;
  };

  return (
    <>
      <Banner data={bannerInfo} />
      <LayoutTemplate>
        <HorizontalElementTemplate>
          <SideImage src={multiLangLogo[i18n.language]} />
          <ContentBox>
            <AboveTitle>{t("project idea")}</AboveTitle>
            <Title big>{t("project_title")}</Title>
            <Lead big>{t("project_lead")}</Lead>
          </ContentBox>
        </HorizontalElementTemplate>
        <Content>{renderHTML(t("project_content"))}</Content>
        <StyledTitle>{t("project_title_2")}</StyledTitle>
        <GridTemplate>{displayProjects()}</GridTemplate>
      </LayoutTemplate>
    </>
  );
};

export default Project;
